import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { capitalizeFirstLetter } from "utils/common";
import Reports from "../Reports";
import HomeMenu from "./home-menu/menu";

const Home = ({role, name}) => {
    return  <Grid container spacing={3}>
    <Grid item xs={12}>
        <Paper elevation={4} sx={{padding: "10px 30px"}}>
            <Typography variant="h5" >{`Welcome, ${name}`}</Typography>
            <Typography variant="caption" display="block" gutterBottom>
                {`Logged in as: ${capitalizeFirstLetter(['supervisor','employee'].includes(role) ? role : role.toUpperCase())}`}
            </Typography>
        </Paper>
    </Grid>
    {role === 'employee' 
        ? <Grid item xs={12}>
            <Paper elevation={4} sx={{padding: "10px 30px"}}>
                <Reports role={role} /> 
            </Paper>
          </Grid>
        : <HomeMenu role={role} />
}
</Grid>
    
}

export default Home;