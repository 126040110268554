import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { ReportConfig } from './PdfConfig'

import FirstBalfourLogo from "assets/images/logo.jpg";
import NmrLogo from "assets/images/nmr_logo.jpg";
import NoiLogo from "assets/images/noi_logo.jpg";
import SeverityMatrix from "assets/images/severity_matrix.png";
import { noiClass } from 'utils/constants';


const col1 = 510 / 6;
// Create styles
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    backgroundColor: '#FFFFFF',
    padding: '20px 50px',
    fontSize: '8px'
  },
  mainLogo: {
    height: '50px',
    width: '150px',
    margin: '0 auto',
  },
  reportLogo: {
    height: '22px',
    width: '40px',
    marginLeft: '15px',
    marginRight: '100px'
  },    
  header: {
      display: 'flex',
      flexDirection: 'row',
      padding: 1,
      textAlign: 'center'
  },
  sectionBoardered: {
    margin: '10px 0',
    padding: '5px',
    fontSize: '8px',
    border: '1px solid black',
    width: '510px',
    display: 'flex',
    flexDirection: 'column'
  },
  value: {
    padding: 5,
    fontSize: '8px',
    border: '1px solid black',
  },
  section: {
    padding: 10,
    display: 'flex',
  },
  divider: {
    borderTop: '1px solid #C0C0C0',
    width: '510px',
    margin: '10px 0'
  },
  sectionDivider: {
    borderTop: '1px solid #C0C0C0',
    width: '480px',
    margin: '10px'
  },
  row: {
    padding: '3px',
    display: 'flex',
    flexDirection: 'row'
  },
  col1: {
    width: `${col1}px`
  },
  col2: {
    width: `${col1 * 2}px`
  },
  col3: {
    width: `${col1 * 3}px`
  },
  col4: {
    width: `${col1 * 4}px`
  },
  col5: {
    width: `${col1 * 5}px`
  },
  col6: {
    width: `${col1 * 6}px`
  },
  severityMatrix: {
    width: '510px'
  }
});

const cleanMultiline = (text) => !text ? text : text.replace(/\n\n/gm,'@!').replace(/\n/gm, '').replace('@!',`\n`);

const Bold = ({children}) => <Text style={{fontFamily: 'Helvetica-Bold'}}>{children}</Text>
const Value = ({value}) => <Text>{cleanMultiline(value)}</Text>
const Input = ({style, value, label}) => <Text style={style}><Bold>{label+': '}</Bold><Value value={value}/></Text>
const MultiInput = ({ data=[], reportKey=''}) => {
  if(!data || !data.length) return null;

  return <View style={styles.sectionBoardered}>
      <View style={{...styles.row, marginBottom: '10px'}}>
        <Text style={{fontSize: '10px', fontFamily: 'Helvetica-Bold'}}>{ReportConfig[reportKey].label}</Text>
      </View>
      {data.map((inputData, dataIndex) => {
        return (
          <> 
           {ReportConfig[reportKey].rows.map(rowInputs => {
            const rowKeys = Object.keys(rowInputs);
            return <View style={styles.row} break>
                {rowKeys.map(rowKey => {
                  const { label, size } = rowInputs[rowKey]
                  return <Input style={styles[size]} label={label} value={inputData[rowKey]} />
                })}
              </View>
            })}
            {(dataIndex+1 !== data.length) && 
                <View style={styles.sectionDivider} />
            }
          </>
        )
      })}
    </View>
}
const ImageAttachment = ({label, src}) => <View style={{width: `${510/3}px`, height: `${510/3}px`, border: '1px solid black'}}>
  <Image src={src}/>
  <Text style={{textAlign: 'center', padding: '5px'}}>{label}</Text>
</View>

const NoiReport = ({reportData}) => <>
        <View style={styles.divider} />
          <Text style={{fontSize: '10px', padding: '10px 0', fontFamily: 'Helvetica-Bold'}}>Incident Details</Text>
        <View  style={styles.row}>
          <Text style={styles.col6}><Bold>{ReportConfig.description_of_accident}</Bold></Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.col6}>{cleanMultiline(reportData.description_of_accident)}</Text>
        </View>
        <View style={styles.divider} />
        <MultiInput data={reportData.witnesses} reportKey='witnesses' />
        <MultiInput data={reportData.personnel_involved} reportKey='personnel_involved' />
        <MultiInput data={reportData.personnel_accident_details} reportKey='personnel_accident_details' />
        <MultiInput data={reportData.environmental_impact} reportKey='environmental_impact' />
        <MultiInput data={reportData.other_property_damage} reportKey='other_property_damage' />
        <MultiInput data={reportData.property_damage} reportKey='property_damage' />
        <MultiInput data={reportData.initial_actions} reportKey='initial_actions' />
        <View style={styles.divider} />
        <View  style={styles.row}>
          <Input style={styles.col6} label={ReportConfig.recommended_action_taken_date} value={reportData.recommended_action_taken_date} />
        </View>
        <View  style={styles.row}>
          <Input style={styles.col6} label={ReportConfig.recommended_action_taken} value={reportData.recommended_action_taken} />
        </View>
        <View style={styles.divider} />
        <MultiInput data={reportData.notifications_made} reportKey='notifications_made' />
        <View>
          <Image src={SeverityMatrix} style={styles.severityMatrix} />
          <View style={{...styles.row, padding: '0', border: '1px solid black', backgroundColor: '#D3D3D3'}}>
            <View style={{...styles.col4, padding: '5px', borderRight: '1px solid black', textAlign: 'center'}}>
              <Text style={{fontFamily: 'Helvetica-Bold'}}>Type of Loss/Damage</Text>
            </View>
            <View style={{...styles.col4, padding: '5px', borderRight: '1px solid black', textAlign: 'center'}}>
              <Text style={{fontFamily: 'Helvetica-Bold'}}>Actual Severity</Text>
            </View>
            <View style={{...styles.col4, padding: '5px', textAlign: 'center'}}>
              <Text style={{fontFamily: 'Helvetica-Bold'}}>Potential Severity</Text>
            </View>
          </View>
          <View style={{...styles.row, padding: '0',  marginTop: '-2px', border: '1px solid black'}}>
            <View style={{...styles.col4, padding: '5px', borderRight: '1px solid black'}}>
              <Text>Personnel Injury</Text>
            </View>
            <View style={{...styles.col4, padding: '5px', borderRight: '1px solid black', textAlign: 'center'}}>
              <Text>{reportData.personnel_injury_as}</Text>
            </View>
            <View style={{...styles.col4, padding: '5px', textAlign: 'center'}}>
              <Text>{reportData.personnel_injury_ps}</Text>
            </View>
          </View>
          <View style={{...styles.row, padding: '0',  marginTop: '-2px', border: '1px solid black'}}>
            <View style={{...styles.col4, padding: '5px', borderRight: '1px solid black'}}>
              <Text>Asset Damage Costs</Text>
            </View>
            <View style={{...styles.col4, padding: '5px', borderRight: '1px solid black', textAlign: 'center'}}>
              <Text>{reportData.asset_damage_cost_as}</Text>
            </View>
            <View style={{...styles.col4, padding: '5px', textAlign: 'center'}}>
              <Text>{reportData.asset_damage_cost_ps}</Text>
            </View>
          </View>
          <View style={{...styles.row, padding: '0',  marginTop: '-2px', border: '1px solid black'}}>
            <View style={{...styles.col4, padding: '5px', borderRight: '1px solid black'}}>
              <Text>Environmental Spill Damage (include volume spilled and cleanup costs)</Text>
            </View>
            <View style={{...styles.col4, padding: '5px', borderRight: '1px solid black', textAlign: 'center'}}>
              <Text>{reportData.environmental_damage_as}</Text>
            </View>
            <View style={{...styles.col4, padding: '5px', textAlign: 'center'}}>
              <Text>{reportData.environmental_damage_ps}</Text>
            </View>
          </View>
          <View style={{...styles.row, padding: '0', marginTop: '-2px', border: '1px solid black'}}>
            <View style={{...styles.col4, padding: '5px', borderRight: '1px solid black'}}>
              <Text>Damage to Company's Reputation (include any fines/penalties)</Text>
            </View>
            <View style={{...styles.col4, padding: '5px', borderRight: '1px solid black', textAlign: 'center'}}>
              <Text>{reportData.damage_to_company_reputation_as}</Text>
            </View>
            <View style={{...styles.col4, padding: '5px', textAlign: 'center'}}>
              <Text>{reportData.damage_to_company_reputation_ps}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <Text><Text style={{fontFamily: 'Helvetica-Bold'}}>INVESTIGATION TEAM</Text>(List all personnel to be involved in the investigation based on the gihgest actual or potential serverity rating listed above)</Text>
          </View>
          <View style={{...styles.row, padding: '0', border: '1px solid black', backgroundColor: '#D3D3D3'}}>
            <View style={{...styles.col2, padding: '3px', borderRight: '1px solid black', position: 'relative'}}>
                <Text style={{...styles.row, fontFamily: 'Helvetica-Bold'}}>1. Project ESH Specialist</Text>
                <Text style={{...styles.row, fontFamily: 'Helvetica-Bold'}}>2. Immediate Supervisor</Text>

                <Text style={{...styles.row, textAlign: 'center', position: 'absolute', bottom: '0', left: '0', right: '0'}}>(Rating 1-25 Severity)</Text>
            </View>
            <View style={{...styles.col2, padding: '5px', borderRight: '1px solid black', position: 'relative'}}>
                <Text style={{...styles.row, fontFamily: 'Helvetica-Bold'}}>1. Project ESH Supervisor / Manager</Text>
                <Text style={{...styles.row, fontFamily: 'Helvetica-Bold'}}>2. Construction Manager</Text>

                <Text style={{...styles.row, textAlign: 'center', position: 'absolute', bottom: '0', left: '0', right: '0'}}>(Rating 1-25 Severity)</Text>
            </View>
            <View style={{...styles.col2, padding: '5px', borderRight: '1px solid black', position: 'relative'}}>
                <Text style={{...styles.row, fontFamily: 'Helvetica-Bold'}}>1. Site ESH Sr. Supervisor / Assistant ESH Manager</Text>
                <Text style={{...styles.row, fontFamily: 'Helvetica-Bold'}}>2. Construction Manager</Text>

                <Text style={{...styles.row, textAlign: 'center', position: 'absolute', bottom: '0', left: '0', right: '0'}}>(Rating 7 or greater Severity)</Text>
            </View>
            <View style={{...styles.col3, padding: '5px', borderRight: '1px solid black', position: 'relative'}}>
                <Text style={{...styles.row, fontFamily: 'Helvetica-Bold'}}>1. Project Manager / Department Head</Text>
                <Text style={{...styles.row, fontFamily: 'Helvetica-Bold'}}>2. Corporate ESH Manager</Text>

                <Text style={{...styles.row, textAlign: 'center', position: 'absolute', bottom: '0', left: '0', right: '0'}}>(Rating 15 or greater Severity)</Text>
            </View>
            <View style={{...styles.col3, padding: '5px', position: 'relative'}}>
                <Text style={{...styles.row, fontFamily: 'Helvetica-Bold'}}>1. Corporate ESH Head</Text>
                <Text style={{...styles.row, fontFamily: 'Helvetica-Bold'}}>2. Investigation Committee</Text>
                <Text style={{...styles.row, fontFamily: 'Helvetica-Bold', marginBottom: '20px'}}>3. Qualified Independento of the Operations Unit where the incident occured appointed by FB Operations Head / President</Text>

                <Text style={{...styles.row, textAlign: 'center', position: 'absolute', bottom: '0', left: '0', right: '0'}}>(Rating 20 or greater Severity)</Text>
            </View>
          </View>
          <View style={{...styles.row, padding: '0', border: '1px solid black'}}>
              <View style={{...styles.col2, padding: '5px', borderRight: '1px solid black'}}>
                <Text>{reportData.investigation_team_1}</Text>
              </View>
              <View style={{...styles.col2, padding: '5px', borderRight: '1px solid black'}}>
                <Text>{reportData.investigation_team_2}</Text>
              </View>
              <View style={{...styles.col2, padding: '5px', borderRight: '1px solid black'}}>
                <Text>{reportData.investigation_team_3}</Text>
              </View>
              <View style={{...styles.col3, padding: '5px', borderRight: '1px solid black'}}>
                <Text>{reportData.investigation_team_4}</Text>
              </View>
              <View style={{...styles.col3, padding: '5px'}}>
                <Text>{reportData.investigation_team_5}</Text>
              </View>
          </View>
        </View>

        
        <View style={styles.divider} />
        <Text style={{fontSize: '10px', padding: '10px 0', fontFamily: 'Helvetica-Bold'}}>Images</Text>
        <View>
          {reportData.image.reduce((accumulator, image, index) => {
            const currentRow = Math.floor(index/3)
            if(!accumulator[currentRow]) accumulator.push([])
            accumulator[currentRow].push(<ImageAttachment src={image.src} label={image.label} />)
            return accumulator
          }, []).map(images => <View style={styles.row}>
            {images}
          </View>)}
        </View>
</>

const NmrReport = ({reportData}) => <>
        <View style={styles.divider} />
          <Text style={{fontSize: '10px', padding: '10px 0', fontFamily: 'Helvetica-Bold'}}>Near Miss Information</Text>
        <View  style={styles.row}>
          <Text style={styles.col6}><Bold>{ReportConfig.near_miss_description}</Bold></Text>
        </View>
        <View style={{...styles.row, marginBottom: '10px'}}>
          <Text style={styles.col6}>{cleanMultiline(reportData.near_miss_description)}</Text>
        </View>
        <View  style={styles.row}>
          <Text style={styles.col6}><Bold>{ReportConfig.near_miss_action}</Bold></Text>
        </View>
        <View style={{...styles.row, marginBottom: '10px'}}>
          <Text style={styles.col6}>{cleanMultiline(reportData.near_miss_action)}</Text>
        </View>
        <View  style={styles.row}>
          <Text style={styles.col6}><Bold>{ReportConfig.method_of_addressing}</Bold></Text>
        </View>
        <View style={{...styles.row, marginBottom: '10px'}}>
          <Text style={styles.col6}>{cleanMultiline(reportData.method_of_addressing)}</Text>
        </View>
        <View  style={styles.row}>
          <Text style={styles.col6}><Bold>{ReportConfig.potential_severity_explanation}</Bold></Text>
        </View>
        <View style={{...styles.row, marginBottom: '10px'}}>
          <Text style={styles.col6}>{cleanMultiline(reportData.potential_severity_explanation)}</Text>
        </View>
        <View style={styles.row}>
          <Input style={styles.col6} label={ReportConfig.type_of_loss} value={reportData.type_of_loss} />
        </View>
        <MultiInput data={reportData.recommendations} reportKey='recommendations' />
        <View>
          <View style={styles.divider} />
          <View style={styles.row}>
            <Input style={{...styles.col3, textAlign: 'center'}} label={ReportConfig.actual} value={reportData.actual} />
            <Input style={{...styles.col3, textAlign: 'center'}} label={ReportConfig.potential} value={reportData.potential} />
          </View>
          <Image src={SeverityMatrix} style={styles.severityMatrix} />
          <View style={styles.divider} />
          <View style={styles.row}>
            <Input style={{...styles.col3, textAlign: 'center'}} label={ReportConfig.actual} value={reportData.actual} />
            <Input style={{...styles.col3, textAlign: 'center'}} label={ReportConfig.potential} value={reportData.potential} />
          </View>
        </View>
        <View style={styles.divider} />
        <Text style={{fontSize: '10px', padding: '10px 0', fontFamily: 'Helvetica-Bold'}}>Images</Text>
        <View>
          {reportData.image.reduce((accumulator, image, index) => {
            const currentRow = Math.floor(index/3)
            if(!accumulator[currentRow]) accumulator.push([])
            accumulator[currentRow].push(<ImageAttachment src={image.src} label={image.label} />)
            return accumulator
          }, []).map(images => <View style={styles.row}>
            {images}
          </View>)}
        </View>
</>

// Create Document Component
const MyDocument = ({reportData = {}, state = {}}) => {
  const { projects, pm: pms, supervisor: supervisors } = state;

  return (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <Image src={FirstBalfourLogo} style={styles.mainLogo} />
      <View style={styles.header}>
        <Image src={reportData.report_type === 1 ? NoiLogo : NmrLogo} style={styles.reportLogo} />
        <Text style={{fontFamily: 'Helvetica-Bold', fontSize: '14px', marginLeft: reportData.report_type === 1 ? '25px' : '42px'}}>{reportData.report_type === 1 ? "Notice of Incident Report" : "Near Miss Report"}</Text>
      </View>
      <View style={styles.section}>
        <View style={styles.row}>
          <Input style={styles.col3} label={(reportData.report_type === 1 ? "Noi" : "Nmr") + " Number"} value={reportData.report_id} />
          <Input style={styles.col3} label={ReportConfig.date_time} value={reportData.date_time} />
        </View>
        <View  style={styles.row}>
          <Input style={styles.col3} label={ReportConfig.project} value={projects[reportData.project] ? projects[reportData.project].name : ''} />
          <Input style={styles.col3} label={ReportConfig.shift} value={reportData.shift} />
        </View>
        <View style={styles.row}>
          <Input style={styles.col3} label={ReportConfig.incident} value={reportData.incident} />
          <Input style={styles.col3} label={ReportConfig.incident_classification} value={reportData.report_type === 1 ? Object.values(noiClass)[reportData.incident_classification-1] : "Near Miss"} />
        </View>
        <View  style={styles.row}>
          <Input style={styles.col3} label={ReportConfig.location} value={reportData.location} />
          <Input style={styles.col3} label={ReportConfig.activity} value={reportData.activity} />
        </View>
        <View  style={styles.row}>
          <Input style={styles.col3} label={ReportConfig.department_head} value={pms[reportData.department_head] ? pms[reportData.department_head].name : ''} />
          <Input style={styles.col3} label={ReportConfig.supervisor} value={supervisors[reportData.supervisor] ? supervisors[reportData.supervisor].name : ''} />
        </View>
        {reportData.report_type === 1 && <NoiReport reportData={reportData} />}
        {reportData.report_type === 2 && <NmrReport reportData={reportData} />}
      </View>
    </Page>
  </Document>
)};

export default MyDocument;