import DownloadPdf from "components/common/downloadPdf"

export const Projects = [
    "2118 - MCD LNG Civil Works",
    "2101 - Aeropack Site Development Works",
    "2155 - Southern Star Various Projects",
    "2116 - (LGBU) Construction of the Mahanagdong-A Additional Reinjection Line for TGP",
    "2039 - Package A: Taguig 500 kV Substation Project ( Asssociated Transmission Lines)",
    "2076 - Southern Star Project CP-08 - Civil Works (C3, C5, and C7)",
    "2093 - Southern Star Project - Roads",
    "2129 - Design and Build of East Bay Phase 1 Water Treatment Plant",
    "1960 - NBAQ4",
    "2140 - (MAGBU) M3 Binary Plant Project - FCRS Package",
    "2139 - (MAGBU) M3 Binary Plant Project - CA Package",
    "2167 - LGBU Civil Works Routine & Maintenance Services",
    "2152 - NIGBU Clustered RI Lines",
    "2138 - (MAGBU) M3 Binary Plant Project - BOP Package",
    "2106 - CSA Works for B3 Maintenance, Pipe Rack 11 and TTLR",
    "2103 - (LGBU) Pad UMD New Road and Pad Construction",
    "2161 - OHTL Maintenance Works",
    "2132 - WINBU Civil Works and Routine Maintenance Services",
    "2148 - Typhoon Proofing in Mahanagdong & Upper Mahiao",
    "2162 - KL7RD & KL5RD Hook-up Project",
    "2146 - Proposed FPIP Helipad Mechanical & Fire Protection Works",
    "2082 - (LGBU) Bilateral Steam Exchange Phase 2 (Leytex Project)",
    "2166 - Makban SPEC Project",
    "2119 - Palayan Binary Plant Project - BOP Package",
    "2158 - LGBU Sumps Repair",
    "2160 - Permanent Condensate Disposal System for the Tongonan FCRS",
    "2121 - Palayan Binary Plant Project - FCRS Package",
    "2120 - Palayan Binary Plant Project - CA Package",
    "2163 - Meralco-Murata's Access Road",
]


export const defaultIdState = {
    reportType: "NOI",
    projectId: "XXXX",
    incidentType: "XXX",
    year: "XX",
    reportNumber: "XXXX"
}

export const noiClass = {
    "FAT": "Fatality",
    "LTI": "Lost Time Injury",
    "RWC": "Restructed Work Case",
    "MTC": "Medical Treatment Case",
    "FAC": "First Aid Case",
    "OI": "Occupational Illness",
    "PDE": "Property Damage Equipment",
    "PDV": "Property Damage Vehicular",
    "OPD": "Other Property Damage",
    "ENV": "Environmental",
    "FRE": "Fire",
    "EXP": "Explosion",
    "OTH": "Others",
    "TBA": "To be announced"
}

export const noiClassDivision = {
    "Environmental Impact": [
        "Environmental"
    ],
    "Internal/External Property Damage": [
        "Property Damage Equipment",
        "Property Damage Vehicular",
        "Other Property Damage"
    ],
    "Personal Incident Details": [
        "Fatality",
        "Lost Time Injury",
        "Restructed Work Case",
        "Medical Treatment Case",
        "First Aid Case",
        "Occupational Illness"
    ],
    "Other Incidents": [
        "Fire",
        "Explosion",
        "Others",
        "To be announced"
    ]
    
}

export const nmrClass = {
    "NM": "Near Miss"
}

export const reportType = {
    1: "Notice of Incident",
    2: "Near Miss Report"
}

export const witnessOption = {
    'name': {
        label: "Name",
        size: 6,
    },
    'title': {
        label: "Title/Designation",
        size: 6
    }
}

export const lossToSeverityOptions = {
    'type-of-loss': {
        label: "Type of Loss/Damage",
        size: 4, 
    },
    'actual-severity': {
        label: "Actual Severity",
        size: 4
    },
    'potential-severity': {
        label: "Potential Severity",
        size: 4
    }
}

export const nearMissRecommendationOptions = {
    'action': {
        label: "Action",
        size: 12, 
    },
    'action_by': {
        label: "Action By",
        size: 6
    },
    'date_assign': {
        label: "Date Assigned",
        size: 3,
        fieldType: "datetime-local"
    },
    'date_completion': {
        label: "Completion Date",
        size: 3,
        fieldType: "datetime-local"
    },
}

export const involvedPersonnelOption = {
    name: {
        label: "Name",
        size: 6,
    },
    title: {
        label: "Title/Designation",
        size: 6
    },
    company: {
        label: "Company",
        size: 8
    },
    consecutive_days_work: {
        label: "Consecutive Days Worked",
        size: 2,
        fieldType: 'number'
	},
	years_of_service: {
        label: "Years of Service",
        size: 2,
        fieldType: 'number'
	}
}

const bodyPartsList = [
    'Head',
    'Forehead',
    'Posterior Part',
    'Side/Parietal',
    'Top/Temporal',
    'Left Eye',
    'Right Eye',
    'Left Cheek',
    'Right Cheek',
    'Chin',
    'Left Ear',
    'Right Ear',
    'Mouth',
    'Nose',
    'Neck',
    'Internal Organ',
    'Skin',
    'Others',
    'Abdomen',
    'Left Arm',
    'Right Arm',
    'Lower Back',
    'Middle Back',
    'Upper Back',
    'Left Chest',
    'Right Chest',
    'Left Elbow',
    'Right Elbow',
    'Hands/Fingers',
    'Left Shoulder',
    'Right Shoulder',
    'Trunk',
    'Left Wrist',
    'Right Wrist',
    'Left Hand',
    'Right Hand',
    'Left Thumb',
    'Left Index Finger',
    'Left Middle Finger',
    'Left Ring Finger',
    'Left Piky Finger',
    'Right Thumb',
    'Right Index Finger',
    'Right Middle Finger',
    'Right Ring Finger',
    'Right Piky Finger',
    'Not Determined',
    'Left Ankle',
    'Right Ankle',
    'Anus',
    'Left Buttocks',
    'Right Buttocks',
    'Left Calf',
    'Right Calf',
    'Groin',
    'Left Hips',
    'Right Hips',
    'Left Knee',
    'Right Knee',
    'Left Leg',
    'Right Leg',
    'Left Foot',
    'Right Foot',
    'Left Thigh',
    'Right Thigh',
    'Left Big Toe',
    'Left Index Toe',
    'Left Middle Toe',
    'Left Ring Toe',
    'Left Little Toe',
    'Right Big Toe',
    'Right Index Toe',
    'Right Middle Toe',
    'Right Ring Toe',
    'Right Little Toe',
    'Multiple Injuries'
]

const natureOfIncident = [
    'Absorption',
    'Bodily',
    'Caught Between',
    'Caught in',
    'Caught on',
    'Contact w/ Electricity',
    'Contact w/ Chemicals',
    'Contact w/ Temp Extremes',
    'Fall-different level',
    'Fall-same level/Trips/Slips',
    'Ingestion',
    'Inhalation',
    'Radiation Exposure',
    'Reaction/Over Excertion',
    'Rubbing/Abrading',
    'Stepped into/on to',
    'Struck Against',
    'Struck by Falling Object',
    'Struck by Flying Objects',
    'Struck by Sliding Objects',
    'Struck by Swinging Objects',
    'Stung/Bitten/Scratched by animals',
    'Not Determined',
    'Other'
]

const incidentSource = [
    'Animals/Insects',
    'Biological(Virus, Secretions, etc)',
    'Building/Structure',
    'Beams',
    'Columns',
    'Doors',
    'Handrail/Support',
    'Ladder/Steps/Stairs',
    'Scaffold System',
    'Equipment',
    'Stationary',
    'Heavy Equipment',
    'Electrical Equipment',
    'Food',
    'Powered Hand Tools',
    'Non-powered Hand Tools',
    'Health Condition',
    'Human-induced',
    'Supplies & Materials',
    'Chemical substance/mixures',
    'Welding Rod',
    'Grinding Disc',
    'Vehicle',
    'Vessel/Boat/Barge',
    'Work Environment',
    'Extreme Temperatures',
    'Harmful Gasses',
    'Ionizing Radiation',
    'Noise',
    'Particle/Foreign Bodies',
    'Pressure',
    'Terrain/Work Surface',
    'Tripping Hazards',
    'Weather Conditions',
    'Welding Spark',
    'Other'
]

const natureOfInjury = [
    'Amputation',
    'Asphyxia',
    'Bite/Sting',
    'Burn(Chemical)',
    'Burn/Scald(Heat)',
    'Concussion',
    'Contusion',
    'Corneal Abrasion',
    'Cut/Laceration',
    'Dental Injuries',
    'Dermatitis',
    'Dislocation',
    'Electric shock/Electrocution',
    'Embedded Objects',
    'Embolism',
    'Enucleation',
    'Eye(Rust Ring)',
    'Flash Burn',
    'Foreign Body',
    'Fracture(Crushed)',
    'Hearing Loss/Impairment',
    'Heat Stroke/Heat Exhaustion',
    'Hernia/Rupture',
    'Herniated/Ruptured Disk',
    'Infection',
    'Inflammation/Irritation',
    'Internal Injuries',
    'Nervous Disorder',
    'Non-work related Illness',
    'Not Embedded',
    'Perforation',
    'Poisoning',
    'Pucture/Open Wound',
    'Radiation(Sunburn)',
    'Scratch/Abrasion',
    'Sprain/Strain',
    'Squeeze',
    'Multiple Injuries(Identify all)',
    'Not Determined',
    'Other'
]

export const personnelAccidentOptions = {
	nature_of_accident: {
		label: "Nature of Incident",
		size: 6,
        fieldType: 'autocomplete',
        options: natureOfIncident
	},
	body_part_affected: {
		label: "Body Parts Affected",
		size: 6,
        fieldType: 'autocomplete',
        options: bodyPartsList
	},
	accident_source: {
		label: "Incident Source",
		size: 6,
        fieldType: 'autocomplete',
        options: incidentSource
	},
	nature_of_injury: {
		label: "Nature of Injury/Illness",
		size: 4,
        fieldType: 'autocomplete',
        options: natureOfInjury
	},
	severity_of_injury: {
		label: "Severity of Injury",
		size: 2,
        fieldType: "select",
        options: [
            {label: "Fatality", value: "Fatality"},
            {label: "Lost Time Incident", value: "Lost Time Incident"},
            {label: "Restricted Work Case", value: "Restricted Work Case"},
            {label: "Medical Treatment Case", value: "Medical Treatment Case"},
            {label: "First Aid Case", value: "First Aid Case"},
        ]
	}
}

export const environmentalImpactOptions = {
    environmental_impact: {
        label: "Environmental Impact",
        size: 12,
        multiline: true
    },
    estimated_weight: {
        label: "Estimated Vol/Wt",
        size: 6
    },
    estimated_dmg_cost_environment: {
        label: "Estimated Cost of Damages",
        size: 6
    }
}

export const otherIncidentsOptions = {
    type_of_property_damage: {
        label: "Type of Property Damage (eg. Equipment, Materials, etc)",
        size: 8
    },
    estimated_dmg_cost_property: {
        label: "Est. Cost of Damages",
        size: 4
    },
    description: {
        label: "Description",
        size: 12,
        multiline: true
    }
}

export const propertyDamageOptions = {
    type_of_property_damage: {
        label: "Type of Property Damage (eg. Equipment, Materials, etc)",
        size: 7
    },
    estimated_dmg_cost_property: {
        label: "Est. Cost of Damages",
        size: 3
    },
    internal_external: {
        label: "Internal or External",
        size: 2,
        fieldType: 'select',
        options: [
            {label: "Internal", value: "Internal"},
            {label: "External", value: "External"},
        ]
    },
    description: {
        label: "Description",
        size: 12,
        multiline: true
    }
}

export const initialActionOptions = {
	detail : {
		label: "Details of Immediate Action",
		size: 12,
        multiline: true
	},
	actionee: {
		label: "Actionee",
		size: 8
	},
	date_of_action: {
		label: "Date of Action",
		size: 2,
        fieldType: "datetime-local"
	},
	status: {
		label: "Status",
		size: 2,
        fieldType: 'select',
        options: [{label: "Open", value: "Open"},{label: "Closed", value: "Closed"}]
	}
}

export const notificationOptions = {
    name: {
		label: "Name",
		size: 6
    },
    entitee  : {
		label: "Entity",
		size: 6
    },
    date_time: {
		label: "Date and Time",
		size: 6,
        fieldType: 'datetime-local'
    },
    manner_of_notification: {
        label: "Mode of Notification",
		size: 6,
        fieldType: "select",
        options: [
            {label: "Email", value: "Email"},
            {label: "Text", value: "Text"},
            {label: "Verbal", value: "Verbal"},
            {label: "Phone Call", value: "Phone Call"}
        ]
    }
}

export const initialNmrState = {
    report_type: 2,
    incident:'',
    date_time: '',
    shift:'',
    incident_classification: 1,
    project:'',
    location:'',
    activity:'',
    department_head:'',
    supervisor:'',
    near_miss_description: '',
    near_miss_action: '',
    method_of_addressing: '',
    potential_severity_explanation: '',
    recommendations: [],
    type_of_loss: '',
    actual: '',
    potential: '',
    image: []
}

export const initialNoiState = {
    report_type: 1,
    incident:'',
    date_time: '',
    shift:'',
    incident_classification:'',
    recommended_action_taken: '',
    recommended_action_taken_date: '',
    project:'',
    location:'',
    activity:'',
    department_head:'',
    supervisor:'',
    environmental_impact: [], //  environmental_impact, estimated_weight, estimated_dmg_cost_environment
    other_property_damage: [], //  type_of_property_damage, description, estimated_dmg_cost_property
    property_damage: [], //  type_of_property_damage, description, estimated_dmg_cost_property, internal_external
    witnesses: [],// first_name, last_name
    personnel_involved: [], //  name, company, title, consecutive_days_work, years_of_service
    personnel_accident_details: [], // nature_of_accident, body_part_affected, accident_source, nature_of_injury,severity_of_injury 
    description_of_accident: '',
    initial_actions: [], // detail, actionee, date_of_action, status
    notifications_made: [], // entity, first_name, last_name, date_time, manner_of_notification
    image: [],
    actual: '',
    potential: '',
    report_id: '',
    status: 1,
}

export const reportTableColumns = [
    { field: 'id', headerName: 'Report Number', width: 250 },
    { field: 'incident_name', headerName: 'Incident Name', width: 150  },
    { field: 'incident_class', headerName: 'Incident Class', width: 200 },
    {
      field: 'project_facility',
      headerName: 'Project / Facility',
      width: 400
    },
    {
      field: 'incident_date',
      headerName: 'Date and Time of Incident',
      width: 200 
    }
  ];

  export const finishedReportTableColumns = [
    { field: 'id', headerName: 'Report Number', width: 250 },
    { field: 'incident_name', headerName: 'Incident Name', width: 150  },
    { field: 'incident_class', headerName: 'Incident Class', width: 200 },
    {
      field: 'project_facility',
      headerName: 'Project / Facility',
      width: 400
    },
    {
      field: 'incident_date',
      headerName: 'Date and Time of Incident',
      width: 200 
    },
    {
        field: "action",
        headerName: "Download Pdf",
        sortable: false,
        width: 200,
        renderCell: (params) => <DownloadPdf identifier={params.row.system_id} />
    },
  ];


export const API_ROOT = process.env.REACT_APP_API_ROOT
export const API_URL = process.env.REACT_APP_API_URL
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID