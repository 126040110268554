import { IconButton } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { GlobalContext } from "context/global";
import MyDocument from "components/pdfs/pdf/ReportPdf";
import { API_ROOT, initialNmrState, initialNoiState } from "utils/constants";
import { getReportData } from "utils/api/reports";

const DownloadPdf = ({identifier}) => {
    const [reportData, setReportData] = React.useState(null);
    const dlButtonRef = React.useRef();
    const { state } = React.useContext(GlobalContext);

    const createSrc = (path) => {
        const pathParts = path.split('\\');
        const fileName = pathParts[pathParts.length - 1];
        return `${window.location.origin}/images/${fileName}`
    }

    const addImgSrc = (images) => {
        return images.map(image => ({
            ...image,
            src: createSrc(image.image)
        }));
    }

    const onClick = (e) => {
        if(!reportData) {
            e.preventDefault()
            e.stopPropagation()
            getReportData(identifier).then(res => {
                if(res.status === 200) {
                    const images = addImgSrc(res.data.data.image || [])
                    const newReportData = {
                        ...res.data.data,
                        image: images
                    }
                    const isNoi = res.data.data.report_type === 1
                    setReportData({...(isNoi ? initialNoiState : initialNmrState), ...newReportData});
                }
            })
        }
    }

    React.useEffect(()=>{
        if(reportData) {
            setTimeout(()=>{
                dlButtonRef.current.click()
            }, 5000)
        }
    },[reportData]) 

    // const Downloader = ({hasData, isLoading, buttonRef}) => {
    //     React.useEffect(()=>{
    //         if(hasData && !isLoading && buttonRef) {
    //             buttonRef.current.click()
    //         }
    //     },[hasData, isLoading])

    //     return <span style={{display:'none'}}></span>
    // }


    return  <PDFDownloadLink document={<MyDocument reportData={reportData ? reportData : {}} state={state} />} fileName={reportData ? reportData.report_id : ''}>
        {({ blob, url, loading, error }) => {
            return <IconButton onClick={(e) => onClick(e, loading)} disabled={loading} ref={dlButtonRef} color="primary" aria-label="download pdf" component="span">
              <PictureAsPdfIcon />
              {/* <Downloader reportData={reportData} isLoading={loading} buttonRef={dlButtonRef} /> */}
          </IconButton>}
        }
    </PDFDownloadLink>
}

export default DownloadPdf