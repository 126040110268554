import * as React from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormHelperText } from '@mui/material';

export default function CheckboxesGroup({label, options=[], onChange, value, showError, disabled}) {

  const setInitialValueState = () => {
      const initState = {}
      if(value.length) {
         value.forEach(item => initState[item] = true)
      }
      return initState
  }

  const [state, setState] = React.useState(setInitialValueState);

  const handleChange = (event) => {
    const newState = {
      ...state,
      [event.target.name]: event.target.checked,
    }
    setState(newState);
    let checkedValues = []
    options.forEach(key=> {
      if(newState[key] && !checkedValues.includes(key)){
        checkedValues.push(key)
      }
    })
    if(onChange) onChange(checkedValues);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard" error={showError && value.length === 0}>
        <FormLabel component="legend">{label}</FormLabel>
        <FormGroup>
          {options.map(item => (
            <FormControlLabel control={<Checkbox checked={state[item] ? state[item] : false} onChange={handleChange} name={item} disabled={disabled} />} label={item} />
          ))}
        </FormGroup>
        {(showError && value.length === 0) && <FormHelperText>Please select at least one</FormHelperText>}
      </FormControl>
    </Box>
  );
}
