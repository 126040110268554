export const ReportConfig = {
    incident:'Incident',
    date_time: 'Date and Time of Incident',
    shift:'Shift',
    incident_classification:'Incident Class',
    recommended_action_taken: 'Recommended Action Taken',
    recommended_action_taken_date: 'Recommended Action Date',
    project:'Project',
    location:'Location',
    activity:'Activity',
    department_head:'Project Manager',
    supervisor:'Supervisor',
    environmental_impact: {
        label: 'Environmental Impact',
        rows: [{
            environmental_impact: {
                label: 'Environmental Impact',
                size: 'col6'
            },
            estimated_weight: {
                label: 'Estimated Vol/Wt',
                size: 'col3'
            },
            estimated_dmg_cost_environment: {
                label: 'Estimated Cost of Damages',
                size: 'col3'
            }
        }]
    }, //  environmental_impact, estimated_weight, estimated_dmg_cost_environment
    other_property_damage: {
        label: 'Other Property Damage',
        rows: [
            {
                type_of_property_damage: {
                    label: "Type of Property Damage (eg. Equipment, Materials, etc)",
                    size: "col4"
                },
                estimated_dmg_cost_property: {
                    label: "Est. Cost of Damages",
                    size: "col2"
                },
            },
            {
                description: {
                    label: "Description",
                    size: "col6"
                }
            }
        ]
    }, //  type_of_property_damage, description, estimated_dmg_cost_property
    property_damage: {
        label: 'Other Property Damage',
        rows: [
            {
                type_of_property_damage: {
                    label: "Type of Property Damage",
                    size: "col2"
                },
                estimated_dmg_cost_property: {
                    label: "Est. Cost of Damages",
                    size: "col2"
                },
                internal_external: {
                    label: "Internal/External",
                    size: "col2"
                }
            },
            {
                description: {
                    label: "Description",
                    size: "col6"
                }
            }
        ]}
    , //  type_of_property_damage, description, estimated_dmg_cost_property, internal_external
    witnesses: {
        label: 'Witnesses',
        rows: [{
            name: {
                label: 'Name',
                size: 'col3'
            },
            title: {
                label: 'Title/Designation',
                size: 'col3'
            }
        }],// name, title
    },
    personnel_involved: {
        label: 'Personnel Involved',
        rows: [
            { 
                name : {
                    label: 'Name',
                    size: 'col3'
                },
                title : {
                    label: 'Title/Designation',
                    size: 'col3'
                }
            },
            {
                company: {
                    label: 'Company',
                    size: 'col2'
                },
                consecutive_days_work: {
                    label: 'Consecutive Days Worked',
                    size: 'col2'
                },
                years_of_service: {
                    label: 'Years of Service',
                    size: 'col2'
                }
            }
        ]
    }, //  name, company, title, consecutive_days_work, years_of_service
    personnel_accident_details: {
        label: "Personnel Incident Details",
        rows: [
            {
                nature_of_accident: {
                    label: "Nature of Accident",
                    size: "col3"
                },
                body_part_affected: {
                    label: "Body Parts Affected",
                    size: "col3"
                }
            },
            {
                accident_source: {
                    label: "Accident Source",
                    size: "col2"
                },
                nature_of_injury: {
                    label: "Nature of Injury",
                    size: "col2"
                },
                severity_of_injury: {
                    label: "Severity of Injury",
                    size: "col2"
                }
            }
        ]
    }, // nature_of_accident, body_part_affected, accident_source, nature_of_injury,severity_of_injury 
    description_of_accident: 'Brief Description of Incident',
    initial_actions: {
        label: "Initial Actions",
        rows: [
            {
                detail: {
                    label: "Details of Immediate Action",
                    size: "col6"
                }
            },
            {
                actionee: {
                    label: "Actionee",
                    size: "col2"
                },
                date_of_action: {
                    label: "Date of Action",
                    size: "col3"
                },
                status: {
                    label: "Status",
                    size: "col1"
                }
            }
        ]
    }, // detail, actionee, date_of_action, status
    notifications_made: {
        label: "Notifications Made",
        rows: [
            {
                name: {
                    label: "Name",
                    size: "col3"
                },
                entitee: {
                    label: "Entity",
                    size: "col3"
                }
            },
            {
                date_time: {
                    label: "Date and Time of Notification",
                    size: "col4"
                },
                manner_of_notification: {
                    label: "Manner of Notification",
                    size: "col2"
                }
            }
        ]
    }, // entity, name, date_time, manner_of_notification
    image: [],
    report_id: 'Noi Number',
    near_miss_description: 'Near Miss Incident Description',
    near_miss_action: 'What action, if any, did you take during and after the Near Miss',
    method_of_addressing: 'Offer YOUR method of addressing the incident',
    potential_severity_explanation: 'Indicate and explain “Potential Severity” Rating',
    recommendations: {
        label: "List any recommendation to prevent/minimize future occurrence",
        rows:[
        {
            action: {
                label: "Action",
                size: "col6"
            }
        },
        {
            action_by: {
                label: "Action By",
                size: "col3"
            },
            date_assign: {
                label: "Date Assigned",
                size: "col3"
            },
            date_completion: {
                label: "Completion Date",
                size: "col3"
            }
        }]
    },
    type_of_loss: 'What type of loss could this near miss have caused?',
    actual: 'Actual Severity',
    potential: 'Potential Severity',
}