import * as React from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import Swal from "sweetalert2";
import { useBlocker } from "./useBlocker";


export default function usePrompt(message, when = true) {
    const blocker = React.useCallback(
        async (tx) => {
        const response = await Swal.fire({
            title: 'Any unsaved changes will be discarded, Are you sure you want to leave?',
            icon: 'question',
            showCancelButton: true,
            showConfirmButton: true
        })
        if (response.isConfirmed) tx.retry();
      },
      [message]
    );
  
    useBlocker(blocker, when);
}  