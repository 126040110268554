import ACTIONS from "reducer/global.actions";

const initialUserState = {
    user: {
        authenticated: false,
        role: {},
        info: {}
    },
    supervisor: {},
    pm: {},
    projects: {}
}


export const initialGlobalState = {
    ...initialUserState
}

export function globalReducer(state, action) {
    switch (action.type) {
        case ACTIONS.auth_success:
        case ACTIONS.login_success: return {
            ...state,
            user: {
                authenticated: true,
                role: action.payload.role,
                info: action.payload.user
            }
        }
        case ACTIONS.logout_success: return {
            ...state,
            ...initialUserState
        }

        case ACTIONS.set_user_info: return {
            ...state,
            projects: action.payload.projects,
            pm: action.payload.pm,
            supervisor: action.payload.supervisor
        }

        default: return state
    }
}

