import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const translateData = (data, idName) => {
    if(data.length === 0) return []
    return data.map(item => {
        const {[idName]: idData, ...restOfData} = item

        return {
            id: idData,
            ...restOfData
        }
    })
}

export default function DataTable({data=[], columns=[], idName, style={}, ...params}) {
  return (
    <div style={{ height: '400px', width: '100%' ,padding: 0, ...style}}>
      <DataGrid
        style={{padding: 0}}
        rows={idName ? translateData(data, idName) : data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        {...params}
      />
    </div>
  );
}
