import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const SelectWrapper = ({id, size="small", label, onChange, value, options=[],showError, helperText, ...otherProps}) => {
    return <FormControl fullWidth error={!Boolean(value) && showError}>
    <InputLabel id={`${id}-label`}>{label}</InputLabel>
        <Select
            size={size}
            labelId={`${id}-label`}
            id={`${id}-select`}
            value={value}
            label={label}
            onChange={onChange}
            {...otherProps}
        >
            {options.map((item) => typeof item === 'string' 
                    ? <MenuItem key={item} value={item}>{item}</MenuItem>
                    : <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>)}
        </Select>
        {(!Boolean(value) && showError) && <FormHelperText>{helperText ?? "Please select a value"}</FormHelperText>}
    </FormControl>
}

export default SelectWrapper