import { TextField } from "@mui/material"
import React from "react"

const WrappedTextField = ({value,showError, validator, ...props}) => {
    const [currentValue, setCurrentValue] = React.useState(value)
    const [validField, setValidField] = React.useState(false);

    const handleBlur = (e) => {
        if(validator && !validator(e.target.value)) {
            setCurrentValue('')
            props.onBlur('')
            setValidField(true)
            return 
        }
        setValidField(false)
        setCurrentValue(e.target.value)
        props.onBlur(currentValue)
    }

    React.useEffect(()=>{
        setCurrentValue(value);
    },[value])

    return <TextField value={currentValue}
                error={!Boolean(currentValue) && showError || validField} 
                helperText={((!Boolean(currentValue) && showError) || validField) && 'Please fill in field'}
                {...props}
                onBlur={handleBlur} 
                onChange={e=>setCurrentValue(e.target.value)}
                 />
}

export default WrappedTextField