import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function ModalLoading({isOpen}) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
      <Backdrop
        sx={{zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isOpen || open}
        onClick={handleClose}
      >
        <CircularProgress />
      </Backdrop>
  );
}
