import React from "react";
import { Typography, Grid, TextField } from "@mui/material";

import { 
    witnessOption, 
    involvedPersonnelOption, 
    personnelAccidentOptions,
    initialActionOptions,
    notificationOptions,
    propertyDamageOptions,
    otherIncidentsOptions,
    environmentalImpactOptions,
    } from "utils/constants";

import MultiFields from "components/common/multifields";
import WrappedTextField from "components/common/textfield";

const NoiForm = ({reportData, onChange, disabled, showError, reportDivision}) => {
    return (<>
        <Grid spacing={3} container sx={{padding: '20px 30px'}}>
              <Grid item xs={12}>
                  <Typography variant="h5">Incident Details</Typography>
              </Grid>
              <Grid item xs={12}>
                  <WrappedTextField
                      id="incident-description"
                      label="Brief description of Incident"
                      fullWidth
                      multiline
                      value={reportData.description_of_accident}
                      size="small"
                      onBlur={(value)=>onChange(value,'description_of_accident')}
                      disabled={disabled}
                      showError={showError}
                  />
              </Grid>
              <Grid item xs={12}>
                  <MultiFields 
                        label="Witnesses" 
                        onChange={(value)=>onChange(value,'witnesses')} 
                        value={reportData.witnesses} 
                        options={witnessOption}
                        disabled={disabled}
                        showError={showError}
                        />
              </Grid>
              <Grid item xs={12}>
                  <MultiFields 
                        label="Personnel Involved" 
                        onChange={(value)=>onChange(value,'personnel_involved')} 
                        value={reportData.personnel_involved} 
                        options={involvedPersonnelOption}
                        disabled={disabled}
                        showError={showError}
                        />
              </Grid>
              <Grid item xs={12}>
                  {reportDivision === "Personal Incident Details" &&
                      <MultiFields 
                        label="Personnel Incident Details"
                        onChange={(value)=>onChange(value,'personnel_accident_details')} 
                        value={reportData.personnel_accident_details}
                        options={personnelAccidentOptions}
                        disabled={disabled}
                        showError={showError}
                      />
                  }
                  {reportDivision === "Internal/External Property Damage" &&
                      <MultiFields 
                        label="Internal/External Property Damage"
                        onChange={(value)=>onChange(value,'property_damage')} 
                        value={reportData.property_damage}
                        options={propertyDamageOptions}
                        disabled={disabled}
                        showError={showError}
                      />
                  }
                  {reportDivision === "Environmental Impact" &&
                      <MultiFields 
                        label="Environmental Impact"
                        onChange={(value)=>onChange(value,'environmental_impact')} 
                        value={reportData.environmental_impact}
                        options={environmentalImpactOptions}
                        disabled={disabled}
                        showError={showError}
                      />
                  }
                  {reportDivision === "Other Incidents" &&
                      <MultiFields 
                        label="Other Incidents"
                        onChange={(value)=>onChange(value,'other_property_damage')} 
                        value={reportData.other_property_damage}
                        options={otherIncidentsOptions}
                        disabled={disabled}
                        showError={showError}
                      />
                  }
              </Grid>
              <Grid item xs={12}>
                  <MultiFields 
                      label="Initial Action Taken to Mitigate Risk, Prevent Escalation, or Recurrence"
                      onChange={(value)=>onChange(value,'initial_actions')} 
                      value={reportData.initial_actions} 
                      options={initialActionOptions}
                      disabled={disabled}
                      showError={showError}
                      />
              </Grid>
              <Grid item xs={10}>
                    <WrappedTextField
                      id="recommended-action-taken"
                      label="Recommended Action Taken"
                      fullWidth
                      multiline
                      value={reportData.recommended_action_taken}
                      size="small"
                      onBlur={(value)=>onChange(value,'recommended_action_taken')}
                      disabled={disabled}
                      showError={showError}
                  />
              </Grid>
              <Grid item xs={2}>
                    <TextField
                        id="recommended action taken date"
                        label="Recommended Action Taken Date"
                        type="datetime-local"
                        size="small"
                        onChange={(e)=>onChange(e.target.value,'recommended_action_taken_date')}
                        fullWidth
                        value={reportData.recommended_action_taken_date ? reportData.recommended_action_taken_date.replace(' ', 'T') : ''}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={disabled}
                        error={!reportData.recommended_action_taken_date && showError}
                        helperText={!reportData.recommended_action_taken_date && showError && "Please fill in required field"}
                        />
              </Grid>
              <Grid item xs={12}>
                  <MultiFields 
                      label="Notifications Made"
                      onChange={(value)=>onChange(value,'notifications_made')} 
                      value={reportData.notifications_made} 
                      options={notificationOptions}
                      disabled={disabled}
                      showError={showError}
                      />
              </Grid>
        </Grid>
        </>)
}

export default NoiForm