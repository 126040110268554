import { Box, Grid, Paper, Typography, TextField, Button, Autocomplete } from "@mui/material";
import React from "react";
import Select from "components/common/select";

const MultiFields = ({label, options={}, onChange, disabled, value, showError}) => {
const [checkErrors, setCheckErrors] = React.useState(false);
const [currentInput, setCurrentInput] = React.useState({});
const [rows, setRows] = React.useState(value || []);

const handleAddClick = () => {
    let hasEmptyFields = false;
    for(let key of Object.keys(options)) {
        console.log(currentInput, key)

        if(!currentInput[key]) {
            console.log(currentInput)
            setCheckErrors(true);
            hasEmptyFields = true
            return
        }
    }
    if(hasEmptyFields) return

    setRows(prevRows => [...prevRows, currentInput])
    setCheckErrors(false);
    setCurrentInput(prevInput => Object.keys(prevInput).reduce((acc, key) => {
        acc[key] = ''
        return acc;
    },{}));
}

const handleDelete = (indexInput) => {
    setRows(prevRows => prevRows.filter((item, index) => index !== indexInput))
}

React.useEffect(()=>{
    if(onChange) onChange(rows);
},[rows]) // eslint-disable-line react-hooks/exhaustive-deps

React.useEffect(()=>{
    setRows(value || []);
},[value])

return <Box sx={{border: `1px solid ${showError && rows.length === 0 ? '#d32f2f' : 'gray'}`, borderRadius: "5px", padding: "20px 10px 0 10px", marginBottom: '20px'}}>
        <Grid container spacing={3} >
            <Grid item xs={12}>
                <Typography>{label}</Typography>
            </Grid>
            <>
                <Grid item xs={12}>
                    {rows.length > 0 && 
                    rows.map((item, index)=> {
                    return (<Paper elevation={1} key={index} sx={{padding: '10px', marginTop: '10px'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={11}>
                                    <Grid container spacing={2}>
                                    {Object.entries(options).map(([key, value], index)=>{
                                        return <Grid item xs={value.size} key={key}>
                                                <TextField 
                                                    key={key} 
                                                    label={value.label} 
                                                    multiline={value.multiline}
                                                    value={value.display ? value.display[item[key]-1] : item[key]}
                                                    onChange={e=>setCurrentInput(prevState => ({...prevState, [key]: e.target.value}))}
                                                    size="small" 
                                                    variant="outlined" 
                                                    fullWidth
                                                    disabled />
                                            </Grid>
                                        })} 
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                    <Button 
                                        fullWidth 
                                        color='error' 
                                        size='small'
                                        variant="contained"
                                        disabled={disabled}
                                        onClick={() => handleDelete(index)}>X</Button>
                                </Grid>
                            </Grid>
                        </Paper>)
                        })
                    }
                    {!disabled && <>
                    <Paper elevation={6} sx={{padding: '10px', marginTop: '10px'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={11}>
                                <Grid container spacing={2}>
                                {Object.entries(options).map(([key, value], index)=>{
                                    return <Grid item xs={value.size} key={key}>
                                            {!['select', 'autocomplete'].includes(value.fieldType) && 
                                                <TextField 
                                                    key={key} 
                                                    label={value.label} 
                                                    value={currentInput[key] ?? ''}
                                                    type={value.fieldType}
                                                    multiline={value.multiline}
                                                    error={checkErrors && !currentInput[key]}
                                                    helperText={checkErrors && !currentInput[key] && "Please fill in field"}
                                                    onChange={e=>setCurrentInput(prevState => ({...prevState, [key]: e.target.value}))}
                                                    size="small" 
                                                    variant="outlined" 
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    fullWidth
                                                />
                                            }
                                            {value.fieldType === 'select' &&
                                                <Select 
                                                    key={key}
                                                    label={value.label}
                                                    value={currentInput[key] ?? ''}
                                                    size="small"
                                                    fullWidth
                                                    showError={checkErrors && !currentInput[key]}
                                                    onChange={e=>setCurrentInput(prevState => ({...prevState, [key]: e.target.value}))}
                                                    options={value.options || []}
                                                />
                                            }
                                            {value.fieldType === 'autocomplete' &&
                                                <Autocomplete
                                                    disablePortal
                                                    id={key}
                                                    size="small"
                                                    fullWidth
                                                    options={value.options || []}
                                                    onChange={(e, newValue)=>{
                                                        setCurrentInput(prevState => ({...prevState, [key]: newValue}))
                                                    }}
                                                    freeSolo
                                                    renderInput={(params) => <TextField {...params} placeholder={value.label} />}
                                                />
                                            }
                                        </Grid>
                                    })} 
                                </Grid>
                            </Grid>
                            <Grid item xs={1}>
                                <Button 
                                    fullWidth 
                                    color='success' 
                                    size='small' 
                                    variant="contained"
                                    onClick={handleAddClick}>
                                        Add
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper></>}
                </Grid>
                <Grid item xs={1}>

                </Grid>
            </>
        </Grid>
        {showError && rows.length === 0 && <Typography sx={{position: 'absolute', marginTop: '5px'}} color='error' variant="caption">Please add an entry</Typography>}
</Box>
}
export default MultiFields;