import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Outlet, useLocation, useNavigate, Navigate } from 'react-router';
import { translatePathToTitle } from 'utils/common';
import { GlobalContext } from 'context/global';
import { removeToken, logout } from 'utils/api/auth';
import GLOBAL_ACTIONS from "reducer/global.actions";
import { useGoogleLogout } from 'react-google-login';
import Swal from 'sweetalert2';
import { GOOGLE_CLIENT_ID } from 'utils/constants';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { dispatch } = React.useContext(GlobalContext);
  const [open, setOpen] = React.useState(false);
  const { signOut } = useGoogleLogout({
    clientId: GOOGLE_CLIENT_ID,
    onLogoutSuccess: ()=>{},
    onFailure: ()=>{},
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleError = () => {
    Swal.fire({
      title: 'Error encountered during logout.',
      icon: 'error',
      confirmButtonText: 'Ok'
    })
    location.reload()
  }

  const handleLogout = (e) => {
    logout().then(res => {
      if(res.status === 200) {
        signOut();
        removeToken()
        dispatch({type: GLOBAL_ACTIONS.logout_success})
        navigate('/login');
        Swal.fire({
          title: 'You have successfully logged out',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      }
    }).catch(()=>{
      handleError()
    })
    e.preventDefault();

  }

  // const logoutSuccess = (response) => {
  //     console.log(response, 'logged out from google');
  //     removeToken()
  //     dispatch({type: GLOBAL_ACTIONS.logout_success})
  //     navigate('/login');
  //     Swal.fire({
  //       title: 'You have successfully logged out',
  //       icon: 'success',
  //       confirmButtonText: 'Ok'
  //     })
  // }

  const textIconMapping = {
    'Home': <HomeIcon />,
    'Reports': <AssignmentIcon />
  }

  if(location.pathname === "/") return <Navigate to="/home" />

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {translatePathToTitle(location.pathname)}
          </Typography>
          {/* {googleAuth ? 
            <GoogleLogout
              clientId={GOOGLE_CLIENT_ID}
              onLogoutSuccess={logoutSuccess}
            /> :
            <Button color="inherit" onClick={handleLogout}>Logout</Button>
          } */}
          <Button color="inherit" onClick={handleLogout}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          {/* <Typography sx={{flexGrow: 1}}>(Logo)</Typography> */}
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {['Home', 'Reports'].map((text, index) => (
            <ListItem button key={text} onClick={()=>navigate(text.toLowerCase())}>
              <ListItemIcon>
                {textIconMapping[text]}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
}
