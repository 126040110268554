import React from "react";
import { useParams, Navigate, useNavigate } from "react-router";
import { Paper, Typography, Fab, Divider, Grid, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import LaunchIcon from '@mui/icons-material/Launch';
import MyDocument from 'components/pdfs/pdf/ReportPdf';
import { PDFDownloadLink } from '@react-pdf/renderer';

import DownloadIcon from '@mui/icons-material/Download';
import PublishIcon from '@mui/icons-material/Publish';
import { 
    noiClass,
    noiClassDivision,
    defaultIdState, 
    reportType, 
    initialNmrState,
    initialNoiState,
    nmrClass,
    API_ROOT,
} from "utils/constants";

import IncidentSeverity from "./IncidentSeverity";
import { 
    approveReport, 
    createReport, 
    getReportData, 
    rejectWithComment, 
    submitDraft,
    updateDraft 
} from "utils/api/reports";

import Select from "components/common/select";
import NoiForm from "components/pages/ReportForm/NoticeOfIncidentForm";
import NearMissForm from "components/pages/ReportForm/NearMissForm";
import WrappedTextField from "components/common/textfield";
import Swal from "sweetalert2";
import ImageUploader from "components/common/imageuploader";
import { GlobalContext } from "context/global";
import usePrompt from "components/hooks/usePrompt";

import NmrLogo from "assets/images/nmr_logo.jpg";
import NoiLogo from "assets/images/noi_logo.jpg";
import ModalLoading from "components/common/modalLoader";

const fabStyle = {
    position: 'sticky',
    top: 100,
    right: 50,
    float: 'right',
    margin: '0 10px',
    zIndex: 999
};

const convertIdObjectToString = (object, trimReportNumber) => {
    const { reportType, projectId, incidentType, year, reportNumber} = object
    return `${reportType}-${projectId}-${incidentType}-${year}${trimReportNumber ? '' : reportNumber}`
}

const convertStringToIdObject = (string) => {
    const [ reportType, projectId, incidentType, yearReportNumber ] = string.split('-')
    const year = yearReportNumber.slice(0, 2) || 'XX';
    const reportNumber = yearReportNumber.slice(2,6) || 'XXXX';
    return {
        reportType,
        projectId,
        incidentType,
        year,
        reportNumber
    }
}

const getIncidentType = (acronym) => {
    return acronym === 'NMR' ? 'NM' : 'XXX';
}

const ReportForm = ({role}) => {
    const { state } = React.useContext(GlobalContext);
    const { reportId, reportParam } = useParams();
    const isEditMode = reportParam === 'edit';
    const isCreateMode = reportParam === 'create';
    const [selectedReportType, setSelectedReportType] = React.useState('Notice of Incident');
    const [identifier, setIdentifier] = React.useState(defaultIdState); 
    const [reportData, setReportData] = React.useState(initialNoiState);
    const [initialImages, setInitialImages] = React.useState([]);
    const [showError, setShowError] = React.useState(false);
    const navigate = useNavigate();
    const [isBlocking, setIsBlocking] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [reportDivision, setReportDivision] = React.useState('');
    const { projects, pm: pms, supervisor: supervisors } = state;
    
    usePrompt('', isBlocking);

    const disabled = !isEditMode && !isCreateMode;

    const createSrc = (path) => {
        const pathParts = path.split('\\');
        const fileName = pathParts[pathParts.length - 1];
        return `${window.location.origin}/images/${fileName}`
    }

    const addImgSrc = (images) => {
        return images.map(image => ({
            ...image,
            src: createSrc(image.image)
        }));
    }
    
    React.useEffect(()=>{
        if(isReportParamNumber(reportParam) || reportId) {
            const identifier = isReportParamNumber(reportParam) ? reportParam : reportId
            getReportData(identifier).then(res => {
                if(res.status === 200) {
                    const images = addImgSrc(res.data.data.image || [])
                    const newReportData = {
                        ...res.data.data,
                        image: images
                    }
                    const isNoi = res.data.data.report_type === 1
                    setReportData({...(isNoi ? initialNoiState : initialNmrState), ...newReportData});
                    setInitialImages(images);
                    setIdentifier(convertStringToIdObject(res.data.data.report_id));
                    setSelectedReportType(isNoi ? 'Notice of Incident' : 'Near Miss Report');
                    if(isNoi) setReportDivisionValue(Object.values(noiClass)[newReportData.incident_classification-1]);
                }
            }).catch(e => {
                console.error(e);
                navigate('/reports');
            })
        }
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    // const saveDraftReminder = () => {
    //     Swal.fire({
    //         title: 'Any unsaved changes will be discarded, Are you sure you want to reload?',
    //         icon: 'question',
    //         showCancelButton: true,
    //         confirmButtonText: 'Reload'
    //     })
    // }

    // React.useEffect(()=>{
    //     window.addEventListener('beforeunload', saveDraftReminder)

    //     return () => {
    //         window.removeEventListener('beforeunload', saveDraftReminder)
    //     }
    // },[])

    const isReportParamNumber = (param) => {
        const paramAsInt = parseInt(param)
        return (!isNaN(paramAsInt) && (typeof paramAsInt === 'number') && (!['create', 'edit'].includes(param.toLowerCase())))
    }

    if( reportParam && reportId && role !== 'supervisor' ) return <Navigate to={`/reports/${reportId}`} />

    const reportTypeOptions = Object.values(reportType);

    const handleCancel = () => {
        Swal.fire({
            title: "Are you sure you want to cancel?",
            icon: "question",
            text: "Any unsaved changes will be lost.",
            showCancelButton: true,
            showConfirmButton: true
        }).then(res => {
            if(res.isConfirmed) {
                navigate(`/reports/${reportId}`)
            }
        })
    }

    const handleShowComment = () => {
        const { comment } = reportData.comment
        const approver = {
            2: 'Esh',
            4: 'Project Manager'
        }
        Swal.fire({
            title: `Rejected by ${approver[reportData.status]}`,
            text: comment,
            cancelButtonText: "Close"
        })
    }

    const getProjectOptions = () => {
        return Object.values(projects).map(item => item.name)
    }

    const handlePmSelect = (e) => {
        const pmName = e.target.value
        const pmId = getItemIdFromName(pms, pmName);
        setReportProperty(pmId, 'department_head')
    }

    const getPmOption = () => {
        const selectedProject = projects[reportData.project] ?? {}
        return pms[selectedProject.pm] ? [pms[selectedProject.pm].name] : []
    }

    const handleSupervisorSelect = (e) => {
        const supervisorName = e.target.value
        const supervisorId = getItemIdFromName(supervisors, supervisorName);
        setReportProperty(supervisorId, 'supervisor')
    }

    const getSupervisorOption = () => {
        const selectedProject = projects[reportData.project] ?? {}
        return  supervisors[selectedProject.supervisor] ? [supervisors[selectedProject.supervisor].name] : []
    }

    const getItemIdFromName = (data, name) => {
        for(let [key, value] of Object.entries(data)) {
            if(value.name === name) {
                return key
            }
        }
    }

    const setReportProperty = (value, propertyName) => {
        if(Array.isArray(value) && !value.length === 0) {
            setIsBlocking(true);
        } 
        if(!Array.isArray(value) && Boolean(value)){
            setIsBlocking(true);
        }
        setReportData((prevState) => ({
            ...prevState,
            [propertyName]: value
        }))
    }

    const promptReportTypeSelectChange = (e) => {
        const currentInitialState = selectedReportType === "Notice of Incident" ? initialNoiState : initialNmrState;
        if(JSON.stringify(reportData) !== JSON.stringify(currentInitialState)){
            Swal.fire({
                title: "Are you sure you want to change Report Type?",
                text: "Any unsaved changes will be lost.",
                icon: "question",
                showCancelButton: true,
                showConfirmButton: true
            }).then(res => {
                if(res.isConfirmed) {
                    handleReportTypeSelectChange(e)
                }
            })
        } else {
            handleReportTypeSelectChange(e)
        }
    }
    
    const handleReportTypeSelectChange = (e) => {
        let str = e.target.value;
        let matches = str.match(/\b(\w)/g); 
        let acronym = matches.join('').toUpperCase();
        setIdentifier(prevState => ({
            ...prevState,
            reportType: acronym,
            incidentType: getIncidentType(acronym)
        }))

        setReportData(acronym === 'NMR' ? initialNmrState : initialNoiState)
        setSelectedReportType(e.target.value)
    }

    const handleProjectSelect = (e) => {
        const projectName = e.target.value
        const projectCode = projectName.replace(" ","").split("-")[0];
        const projectId = getItemIdFromName(projects, projectName);
        setIdentifier(prevState => ({
            ...prevState,
            projectId: projectCode
        }))

        setReportProperty(projectId, 'project');
    }

    const handleDateTimeSelect = (e) => {
        const dateTime = e.target.value
        const year = dateTime.split('-')[0]
        const yearCode =  year.substring(2,4)
        setIdentifier(prevState => ({
            ...prevState,
            year: yearCode
        }))

        setReportProperty(dateTime, 'date_time')
    }

    const updateImageValuesToPayload = (values) => {
        return values
    }

    const handleImageUpload = (images, values) => {
        setReportProperty(updateImageValuesToPayload(values), 'image')
    }

    const handleClassSelect = (e) => {
        const incidentClass = e.target.value
        const classList = selectedReportType === 'Notice of Incident' ? noiClass : nmrClass
        const incidentClassIndex = Object.values(classList).indexOf(incidentClass)
        const classKey = Object.keys(classList)[incidentClassIndex]
        setIdentifier(prevState => ({
            ...prevState,
            incidentType: classKey
        }))

        setReportDivisionValue(incidentClass);
        setReportProperty(incidentClassIndex+1, 'incident_classification')
    }

    const setReportDivisionValue = (incidentClass) => {
        for(let [key,value] of Object.entries(noiClassDivision)) {
            if(value.includes(incidentClass)) {
                setReportDivision(key)
                break;
            }
        }
    }

    const hasEmptyField = () => {
        setShowError(true)
        const defaultData = reportData.report_type === 1 ? initialNoiState : initialNmrState
        let reportDivisionData = ['environmental_impact', 'other_property_damage', 'property_damage', 'personnel_accident_details'];
        switch(reportDivision) {
            case "Environmental Impact": reportDivisionData = reportDivisionData.filter(key => key !== 'environmental_impact');  break;
            case "Internal/External Property Damage": reportDivisionData = reportDivisionData.filter(key => key !== 'property_damage'); break;
            case "Personal Incident Details": reportDivisionData = reportDivisionData.filter(key => key !== 'personnel_accident_details'); break;
            case "Other Incidents": reportDivisionData = reportDivisionData.filter(key => key !== 'other_property_damage'); break;
            default: return;
        }

        for( let key of Object.keys(defaultData) ) {
            if(['image','report_id','loss_to_severity','status', 'type_of_loss', ...reportDivisionData].includes(key)) {
                continue
            }
            const value = reportData[key];
            if(Array.isArray(value)) {
                if(value.length === 0) {
                    setShowError(true)
                    return true
                }
            } else {
                if(!Boolean(value)){
                    setShowError(true)
                    return true
                }
            }
        }
        return false
    }

    const handleSubmit = () => {
        if( hasEmptyField() ) return

        const newReportData = {
            ...reportData,
            report_id: convertIdObjectToString(identifier, ![0,2,4].includes(reportData.status))
        }
        Swal.fire({
            title: 'Do you want to submit the report?',
            showCancelButton: true,
            confirmButtonText: 'Submit',
            icon: 'question',
          }).then((result) => {
            if (result.isConfirmed) {
                setIsBlocking(false);
                setIsLoading(true);
                if([0,2,4].includes(reportData.status)) {
                    submitDraft(reportId, newReportData).then(res => { 
                        setIsLoading(false);
                        Swal.fire({
                            title: 'Report submitted successfully.',
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        }).then(res => {
                            if(res.isConfirmed) {
                                navigate('/reports/#For ESH Approval')
                            }
                        })
                    }).catch((error)=>{
                        setIsLoading(false);
                        console.error(error);
                        Swal.fire({
                            title: 'Error encountered during form submission.',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    })
                } else {
                    createReport(newReportData).then(res => {
                        setIsLoading(false);
                        Swal.fire({
                            title: 'Report submitted successfully.',
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        }).then(res => {
                            if(res.isConfirmed) {
                                navigate('/reports/#For ESH Approval')
                            }
                        })
                    }).catch((error)=>{
                        setIsLoading(false);
                        console.error(error);
                        Swal.fire({
                            title: 'Error encountered during form submission.',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    })
                }
            } 
          })
    }

    const handleSaveDraft = () => {
        const newReportData = {
            ...reportData,
            report_id:  convertIdObjectToString(identifier, !isEditMode),
            status: 0
        }
        Swal.fire({
            title: 'Do you want to save as draft?',
            showCancelButton: true,
            confirmButtonText: 'Save',
            icon: 'question',
          }).then((result) => {
            if (result.isConfirmed) {
               setIsLoading(true);
               setIsBlocking(false);
               if(!isEditMode) {
                    createReport(newReportData).then(res => {
                        setIsLoading(false);
                        Swal.fire({
                            title: 'Report Saved as Draft.',
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        }).then(res => {
                            if(res.isConfirmed) {
                                navigate('/reports/#Draft')
                            }
                        })
                    }).catch((error)=>{
                        setIsLoading(false);
                        console.error(error);
                        Swal.fire({
                            title: 'Error encountered during saving.',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    })
               } else {
                    setIsLoading(true);
                    updateDraft(reportId, newReportData).then(res => {
                        setIsLoading(false);
                        Swal.fire({
                            title: 'Report Saved as Draft.',
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        }).then(res => {
                            if(res.isConfirmed) {
                                navigate('/reports/#Draft')
                            }
                        })
                    }).catch((error)=>{
                        setIsLoading(false);
                        console.error(error);
                        Swal.fire({
                            title: 'Error encountered during saving.',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    })
               }
            } 
          })
    }

    const handleApprove = () => {
        Swal.fire({
            title: "Do you want to approve the report?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: 'Approve',
        }).then(res => {
            if(res.isConfirmed) {
                approveReport(reportParam).then(res => {
                    Swal.fire({
                        title: "Report approved successfully.",
                        icon: "success",
                        confirmButtonText: 'Ok',
                    }).then(res => {
                        if(res.isConfirmed){
                            navigate('/reports')
                        }
                    })
                }).catch((error)=>{
                    console.error(error);
                    Swal.fire({
                        title: 'Error encountered during approval.',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    }).then(res => {
                        if(res.isConfirmed){
                            navigate('/reports')
                        }
                    })
                })
            }
        })
    }

    const handleReject = () => {
        Swal.fire({
            title: "Do you want to reject the report?",
            icon: "question",
            input: 'text',
            inputPlaceholder: "Please enter rejection reason",
            showCancelButton: true,
            confirmButtonText: 'Reject',
            inputValidator: (value) => {
                return new Promise((resolve) => {
                  if (value) {
                    resolve()
                  } else {
                    resolve('You need to input a rejection message.')
                  }
                })
              }
        }).then(res => {
            if(res.isConfirmed && res.value) {
                rejectWithComment({id: reportParam, comment: res.value}).then(res => {
                    Swal.fire({
                        title: "Report Rejected.",
                        icon: "success",
                        confirmButtonText: 'Ok',
                    }).then(res => {
                        if(res.isConfirmed){
                            navigate('/reports')
                        }
                    })
                }).catch((error)=>{
                    console.error(error);
                    Swal.fire({
                        title: 'Error encountered during rejection.',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    }).then(res => {
                        if(res.isConfirmed){
                            navigate('/reports')
                        }
                    })
                })
            }
        })
    }

    const showApproveReject = () => {
        const shouldShow =  (!isEditMode && !isCreateMode)
        if( reportData.status === 1 ) {
            return shouldShow && role === 'esh'
        }
        if( reportData.status === 3) {
            return shouldShow && role === 'pm'
        }
        return false;
    }

    const shouldShowEdit = () => {
        return (!isEditMode && !isCreateMode && (role==='supervisor') && ([0,2,4].includes(reportData.status)))
    }

    return <Paper elevation={4} sx={{padding: '20px', position: "relative"}}>
        {isLoading && <ModalLoading isOpen={isLoading}/>}
        <Grid container sx={{position: 'absolute', marginTop: '10px'}}>
            <Grid item xs={1}>
                <div>
                    <img src={reportData.report_type === 1 ? NoiLogo : NmrLogo} alt="Report Type Logo" style={{height: '70px', marginTop: '-20px', marginLeft: '50px'}} />
                </div>
            </Grid>
            <Grid item xs={3} textAlign='center'>
                <Typography variant="h5">{selectedReportType}</Typography>
            </Grid>
            {isCreateMode && <Grid item xs={3}>
                <Select 
                    id='report-type'
                    value={selectedReportType}
                    label='Report Type'
                    onChange={promptReportTypeSelectChange}
                    options={reportTypeOptions}
                />
            </Grid>}
        </Grid>
        {shouldShowEdit() &&
            <>
            <Fab onClick={()=>navigate(`/reports/edit/${reportParam}`)} color="secondary" size="medium" variant="extended" sx={fabStyle}>
                <EditIcon sx={{ mr: 1 }} />
                Edit
            </Fab>
            {[2,4].includes(reportData.status) && 
                <Fab onClick={handleShowComment}  color="info" size="medium" variant="extended" sx={{...fabStyle, marginLeft: '50px'}}>
                    <LaunchIcon sx={{ mr: 1 }} />
                    Show comment
                </Fab>
            }
            </>
        }
        {showApproveReject() &&
            <>
                <Fab color="warning" size="medium" variant="extended" onClick={handleReject} sx={{...fabStyle, marginLeft: '20px'}}>
                    <CancelPresentationIcon sx={{ mr: 1 }} />
                    Reject
                </Fab>
                <Fab  size="medium" color="success" variant="extended" onClick={handleApprove} sx={fabStyle}>
                    <AssignmentTurnedInIcon sx={{ mr: 1 }} />
                    Approve
                </Fab>
            </>
        }
        {(isEditMode || isCreateMode)  &&
            <>
                {![2,4].includes(reportData.status) && 
                <Fab onClick={handleSaveDraft}  size="medium" color="default" variant="extended" sx={fabStyle}>
                    <SaveIcon sx={{ mr: 1 }} />
                    Save as Draft
                </Fab>}
                {[2,4].includes(reportData.status) && 
                <Fab onClick={handleShowComment}  color="info" size="medium" variant="extended" sx={{...fabStyle, marginLeft: '50px'}}>
                    <LaunchIcon sx={{ mr: 1 }} />
                    Show comment
                </Fab>
                }
                {[0,2,4].includes(reportData.status) &&
                <Fab onClick={handleCancel}  color="secondary" size="medium" variant="extended" sx={{...fabStyle, marginLeft: '50px'}}>
                    <CancelPresentationIcon sx={{ mr: 1 }} />
                    Cancel
                </Fab>
                }
                <Fab onClick={handleSubmit}  color="primary" size="medium" variant="extended" sx={{...fabStyle, marginLeft: '50px'}}>
                    <PublishIcon sx={{ mr: 1 }} />
                    Submit
                </Fab>

            </>
        }
        {(!(isEditMode || isCreateMode) && (reportData.status === 5)) &&
                <PDFDownloadLink document={<MyDocument reportData={reportData} state={state} />} fileName={reportData.report_id}>
                {({ blob, url, loading, error }) =>
                    <Fab disabled={loading} size="medium" color="primary" variant="extended" sx={fabStyle}>
                        <DownloadIcon sx={{ mr: 1 }} />
                               Download PDF
                    </Fab>
                }
                </PDFDownloadLink>
        }
        <Divider sx={{margin: '70px 0 20px 0'}} />
        <Grid spacing={3} container sx={{padding: '10px 30px'}}>
            <Grid item xs={6}>
                <Typography>{"No: " + convertIdObjectToString(identifier)}</Typography>
            </Grid>
            <Grid item xs={3}>
                <TextField
                    id="datetime-local"
                    label="Date and Time of Incident"
                    type="datetime-local"
                    size="small"
                    onChange={handleDateTimeSelect}
                    fullWidth
                    value={reportData.date_time ? reportData.date_time.replace(' ', 'T') : ''}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={disabled && !isEditMode}
                    error={!Boolean(reportData.date_time) && showError}
                    helperText={!Boolean(reportData.date_time) && showError && "Please fill in required field"}
                />
            </Grid>
            <Grid item xs={3}>
                <Select 
                    id='shift'
                    value={reportData.shift}
                    label='Shift'
                    onChange={(e) => setReportProperty(e.target.value, 'shift')}
                    options={['Morning','Mid','Night']}
                    disabled={disabled}
                    showError={showError}
                />
            </Grid>
            <Grid item xs={6}>
                <Select 
                    id='projects'
                    value={(Object.keys(projects).length && reportData.project) ? projects[reportData.project].name  : ""}
                    label='Project'
                    onChange={handleProjectSelect}
                    options={getProjectOptions()}
                    disabled={disabled && !isEditMode}
                    showError={showError}
                />
            </Grid>
            <Grid item xs={3}>
                <Select 
                    id='incident'
                    value={selectedReportType === 'Notice of Incident' 
                                    ?  Object.values(noiClass)[reportData.incident_classification-1] ? Object.values(noiClass)[reportData.incident_classification-1] : ''
                                    : 'Near Miss'}
                    label='Incident Class'
                    onChange={handleClassSelect}
                    options={Object.values(selectedReportType === 'Notice of Incident' ?  Object.values(noiClass) : Object.values(nmrClass))}
                    disabled={disabled && !isEditMode}
                    showError={showError}
                />
            </Grid>
            <Grid item xs={3}>
                <WrappedTextField 
                    fullWidth
                    id='incident'
                    label='Incident'
                    value={reportData.incident}
                    size='small'
                    onBlur={(value)=>setReportProperty(value, 'incident')}
                    disabled={disabled}
                    showError={showError}
                />
            </Grid>
            <Grid item xs={6}>
                <WrappedTextField 
                    fullWidth
                    id='exact-location'
                    value={reportData.location}
                    label='Exact Location'
                    size='small'
                    onBlur={(value)=>setReportProperty(value, 'location')}
                    disabled={disabled}
                    showError={showError}
                />
            </Grid>
            <Grid item xs={6}>
                <WrappedTextField 
                    fullWidth
                    id='specific-activity'
                    value={reportData.activity}
                    label='Specific Activity'
                    size='small'
                    onBlur={(value)=>setReportProperty(value, 'activity')}
                    disabled={disabled}
                    showError={showError}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography vaiant="h5">Relevant Personel</Typography>
            </Grid>
            <Grid item xs={6}>
                <Select 
                    id='project-manager'
                    value={(Object.keys(pms).length && reportData.department_head) ? pms[reportData.department_head].name : ""}
                    label='Project Manager'
                    onChange={handlePmSelect}
                    options={getPmOption()}
                    disabled={disabled}
                    showError={showError}
                />
            </Grid>
            <Grid item xs={6}>
                <Select 
                    id='supervisor'
                    value={(Object.keys(supervisors).length && reportData.supervisor) ?  supervisors[reportData.supervisor].name : ""}
                    label='Supervisor'
                    onChange={handleSupervisorSelect}
                    options={getSupervisorOption()}
                    disabled={disabled}
                    showError={showError}
                />
            </Grid>
        </Grid>
        <Divider sx={{margin: '20px 0'}} />
        {selectedReportType !== 'Near Miss Report' 
            ? <NoiForm  reportData={reportData} onChange={setReportProperty} disabled={disabled} showError={showError} reportDivision={reportDivision} />
            : <NearMissForm reportData={reportData} onChange={setReportProperty} disabled={disabled} showError={showError} />
        }
        
        <IncidentSeverity  reportData={reportData} onChange={setReportProperty} disabled={disabled} showError={showError} />
        <Grid spacing={3} container sx={{padding: '10px 30px'}}>
            <Grid item xs={12}>
                    <ImageUploader disabled={disabled} onChange={handleImageUpload} value={reportData.image} initialValue={initialImages}  />
            </Grid>
        </Grid>
    </Paper>
}

export default ReportForm;