import React from "react";
import { CircularProgress } from "@mui/material";

const centeredStyle = {
    display: 'flex', 
    width: '100%', 
    height: '100vh', 
    justifyContent: 'center', 
    alignItems: 'center'
}

const Loader = () => <div style={centeredStyle}>
    <CircularProgress />
</div>

export default Loader