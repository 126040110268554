import React from "react";
import { Grid, Paper, Button } from "@mui/material";
import { useNavigate } from "react-router";

import CompletedReports from "assets/images/completed_reports.png";
import ForEshApproval from "assets/images/for_esh_approval.png";
import ForPmApproval from "assets/images/for_pm_approval.png";
import ReturnedReports from "assets/images/returned_reports.png";
import CreateReport from "assets/images/create_report.png";
import Draft from "assets/images/draft.png";

const menuItemStyle = {flexGrow: 1, height: '250px', display:'flex', gap: '20px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}
const MenuItem = ({children, ...props}) => <Button fullWidth {...props}>
        <Paper elevation={4} sx={menuItemStyle}>
            {children}
        </Paper> 
    </Button>

const HomeMenu = ({role}) => {
    const navigate = useNavigate();

    return (<> 
        <Grid item xs={4}>
            <MenuItem onClick={()=>navigate('/reports')} >
                <img src={CompletedReports} alt="Completed Reports" style={{width: '100%', height: '100%'}} />
            </MenuItem>
        </Grid>
        {['supervisor'].includes(role) &&
        <>
            <Grid item xs={4}>
                <MenuItem onClick={()=>navigate('/reports/create')} >
                    <img src={CreateReport} alt="Create Report" style={{width: '100%', height: '100%'}} />
                </MenuItem>
            </Grid>
            <Grid item xs={4}>
                <MenuItem onClick={()=>navigate('/reports#Draft')} >
                    <img src={Draft} alt="Draft" style={{width: '100%', height: '100%'}} />
                </MenuItem>
            </Grid>
        </>
        }
        {!['employee', 'pm'].includes(role) &&
            <Grid item xs={4}>
                <MenuItem onClick={()=>navigate('/reports#For ESH approval')} >
                    <img src={ForEshApproval} alt="For Esh Approval" style={{width: '100%', height: '100%'}} />
                </MenuItem>
            </Grid>
        }
        {!['employee'].includes(role) &&
            <Grid item xs={4}>
                <MenuItem onClick={()=>navigate('/reports#Returned by ESH / PM')} >
                    <img src={ReturnedReports} alt="Returned Reports" style={{width: '100%', height: '100%'}} />
                </MenuItem>
            </Grid>
        }
        {!['employee', 'esh'].includes(role) &&
            <Grid item xs={4}>
                <MenuItem onClick={()=>navigate('/reports#For PM approval')} >
                    <img src={ForPmApproval} alt="For PM Approval" style={{width: '100%', height: '100%'}} />
                </MenuItem>
            </Grid>
        }
       
    </>)
}

export default HomeMenu