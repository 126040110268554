import React from "react";
// import GoogleLogo from "assets/images/g-logo.svg";
import { Typography, Box, Paper, TextField, Grid, Button, Divider } from "@mui/material";
import { GlobalContext } from "context/global";
import GLOBAL_ACTIONS from "reducer/global.actions";
import { useNavigate, Navigate } from "react-router";
import { authenticateGoogle, login, saveToken } from "utils/api/auth";
import Swal from "sweetalert2";
import axios from "axios";
import { getProjectDetails } from "utils/api/reports";

import { GoogleLogin } from 'react-google-login';
import { GOOGLE_CLIENT_ID } from "utils/constants";

const boxStyle = {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center'
}

const Login = () => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [checkErrors, setCheckErrors] = React.useState(false);
    let navigate = useNavigate();

    const { state, dispatch } = React.useContext(GlobalContext);
    const { authenticated } = state.user

    const handleLogin = (e) => {
       if(!email || !password) {
           setCheckErrors(true)
           return
       }
       login(email, password).then(res => {
            if(res.data.status !== 0) {
                const { accessToken, role, user } = res.data;
                saveToken(accessToken)
                dispatch({type: GLOBAL_ACTIONS.login_success, payload: {role: role, user: user}})
                axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

                e.preventDefault();
                getProjectInfo();
                navigate('/')
            } else {
                Swal.fire({
                    title: 'Invalid username or password',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: 'error'
                })
            }
        }).catch();       
    }


    const getProjectInfo = () => {
        getProjectDetails().then(res => {
            if(res.status === 200) {
                const { projects, supervisor, pm } = res.data.data;
                dispatch({type: GLOBAL_ACTIONS.set_user_info, payload: {projects, pm, supervisor }})
            }
        })
      }

    const handleKeyDown = (e) => {
        if(e.key === "Enter") {
            e.preventDefault();
            const submit = document.getElementById('login');
            submit.click()
        }
    }

    React.useEffect(()=>{
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    })

    const responseGoogle = (response) => {
        authenticateGoogle(response).then(res => {
            const { accessToken, role, user } = res.data;
            saveToken(accessToken)
            dispatch({type: GLOBAL_ACTIONS.login_success, payload: {role: role, user: user, googleAuth: true}})
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

            getProjectInfo();
            navigate('/')
        })
    }

    const responseGoogleFail = (response) => {
        console.log(response, 'logged out google');
    }

    if(authenticated) {
        return <Navigate to='/' />
    } else {
        return (<Box sx={boxStyle}>
            <Paper sx={{height: 'fit-content', width: 350, padding: "2rem"}} elevation={5}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h5" align="center">Login</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="email" error={checkErrors && !email} helperText={checkErrors && !email && 'Please enter username'} type="email" label="Email" onChange={(e)=>setEmail(e.target.value)} fullWidth variant="outlined" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="password" error={checkErrors && !password} helperText={checkErrors && !password && 'Please enter password'}  type="password" label="Password" onChange={(e)=>setPassword(e.target.value)} fullWidth variant="outlined" />
                    </Grid>
                    <Grid item xs={12}>
                        <Button id="login" variant="contained" fullWidth onClick={handleLogin}>Login</Button>
                    </Grid>
                    <Grid item xs={12}>
                    <Divider><span><b>OR</b></span></Divider>
                    </Grid>
                    <Grid item xs={12}>
                        {/* <Button fullWidth
                                variant="outlined" 
                                onClick={loginWithGoogle}  
                                startIcon={<img src={GoogleLogo} height="30px" alt="Google Logo" />}>
                                    <span><b>Sign in with Google</b></span>
                        </Button> */}
                    <div className="google-login-container">
                        <GoogleLogin
                            clientId={GOOGLE_CLIENT_ID}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogleFail}
                            isSignedIn={true}
                        />
                    </div>

                    </Grid>
                </Grid>
            </Paper>
        </Box>)
    }
}

export default Login