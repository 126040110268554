import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import SeverityMatrix from "assets/images/severity_matrix.png"
import WrappedTextField from "components/common/textfield";

const IncidentSeverity = ({reportData, onChange, disabled, showError}) => {

    const validateInput = (value) => {
        if(parseInt(value) > 25 || parseInt(value) < 0) {
            return false
        }
        return true
    } 

    return <>
        <Divider sx={{margin: '20px 0'}} />
        <Grid spacing={3} container sx={{padding: '20px 30px'}}>
              <Grid item xs={12}>
                  <Typography variant="h5">Incident Severity Details</Typography>
              </Grid>
              <Grid item xs={6}>
                <WrappedTextField 
                    fullWidth
                    id='actual-severity'
                    value={reportData.actual}
                    label='Actual Severity'
                    size='small'
                    validator={validateInput}
                    onBlur={(value)=>onChange(value,'actual')}
                    disabled={disabled}
                    showError={showError}
                    type="number"
                />
            </Grid>
            <Grid item xs={6}>
                <WrappedTextField 
                    fullWidth
                    id='potential-severity'
                    value={reportData.potential}
                    label='Potential Severity'
                    size='small'
                    validator={validateInput}
                    onBlur={(value)=>onChange(value,'potential')}
                    disabled={disabled}
                    showError={showError}
                    type="number"
                />
            </Grid>
            <Grid spacing={0} container sx={{padding: '20px 30px'}}>
                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', border: '1px solid black', background: '#D3D3D3'}}>
                    <Grid item xs={4} sx={{borderRight: '1px solid black', padding: '5px', textAlign: 'center'}}>
                        <span><strong>Type of Loss/Damage</strong></span>
                    </Grid>
                    <Grid item xs={4} sx={{borderRight: '1px solid black', padding: '5px', textAlign: 'center'}}>
                        <span><strong>Actual Severity</strong></span>
                    </Grid>
                    <Grid item xs={4} sx={{padding: '5px', textAlign: 'center'}}>
                        <span><strong>Potential Severity</strong></span>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', border: '1px solid black'}}>
                    <Grid item xs={4} sx={{borderRight: '1px solid black', padding: '5px'}}>
                        Personnel Injury
                    </Grid>
                    <Grid item xs={4} sx={{borderRight: '1px solid black'}}>
                        <WrappedTextField 
                            fullWidth
                            id='personnel_injury_as'
                            value={reportData.personnel_injury_as}
                            validator={validateInput}
                            onBlur={(value)=>onChange(value,'personnel_injury_as')}
                            disabled={disabled}
                            showError={showError}
                            type="number"
                            sx={{'& .MuiOutlinedInput-root' : {height: '100%'} ,height: '100%'}}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <WrappedTextField 
                            fullWidth
                            id='personnel_injury_ps'
                            value={reportData.personnel_injury_ps}
                            validator={validateInput}
                            onBlur={(value)=>onChange(value,'personnel_injury_ps')}
                            disabled={disabled}
                            showError={showError}
                            type="number"
                            sx={{'& .MuiOutlinedInput-root' : {height: '100%'} ,height: '100%'}}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', border: '1px solid black'}}>
                    <Grid item xs={4} sx={{borderRight: '1px solid black', padding: '5px'}}>
                        Asset Damage Costs
                    </Grid>
                    <Grid item xs={4} sx={{borderRight: '1px solid black'}}>
                        <WrappedTextField 
                            fullWidth
                            id='asset_damage_cost_as'
                            value={reportData.asset_damage_cost_as}
                            validator={validateInput}
                            onBlur={(value)=>onChange(value,'asset_damage_cost_as')}
                            disabled={disabled}
                            showError={showError}
                            type="number"
                            sx={{'& .MuiOutlinedInput-root' : {height: '100%'} ,height: '100%'}}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <WrappedTextField 
                            fullWidth
                            id='asset_damage_cost_ps'
                            value={reportData.asset_damage_cost_ps}
                            validator={validateInput}
                            onBlur={(value)=>onChange(value,'asset_damage_cost_ps')}
                            disabled={disabled}
                            showError={showError}
                            type="number"
                            sx={{'& .MuiOutlinedInput-root' : {height: '100%'} ,height: '100%'}}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', border: '1px solid black'}}>
                    <Grid item xs={4} sx={{borderRight: '1px solid black', padding: '5px'}}>
                        Environmental Spill Damage
                        (include volume spilled and cleanup costs)
                    </Grid>
                    <Grid item xs={4} sx={{borderRight: '1px solid black'}}>
                        <WrappedTextField 
                            fullWidth
                            id='environmental_damage_as'
                            value={reportData.environmental_damage_as}
                            validator={validateInput}
                            onBlur={(value)=>onChange(value,'environmental_damage_as')}
                            disabled={disabled}
                            showError={showError}
                            type="number"
                            sx={{'& .MuiOutlinedInput-root' : {height: '100%'} ,height: '100%'}}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <WrappedTextField 
                            fullWidth
                            id='environmental_damage_ps'
                            value={reportData.environmental_damage_ps}
                            validator={validateInput}
                            onBlur={(value)=>onChange(value,'environmental_damage_ps')}
                            disabled={disabled}
                            showError={showError}
                            type="number"
                            sx={{'& .MuiOutlinedInput-root' : {height: '100%'} ,height: '100%'}}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', border: '1px solid black'}}>
                    <Grid item xs={4} sx={{borderRight: '1px solid black', padding: '5px'}}>
                        Damage to Company's Reputation
                        (include any fines/penalties)
                    </Grid>
                    <Grid item xs={4} sx={{borderRight: '1px solid black'}}>
                        <WrappedTextField 
                            fullWidth
                            id='damage_to_company_reputation_as'
                            value={reportData.damage_to_company_reputation_as}
                            validator={validateInput}
                            onBlur={(value)=>onChange(value,'damage_to_company_reputation_as')}
                            disabled={disabled}
                            showError={showError}
                            type="number"
                            sx={{'& .MuiOutlinedInput-root' : {height: '100%'} ,height: '100%'}}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <WrappedTextField 
                            fullWidth
                            id='damage_to_company_reputation_ps'
                            value={reportData.damage_to_company_reputation_ps}
                            validator={validateInput}
                            onBlur={(value)=>onChange(value,'damage_to_company_reputation_ps')}
                            disabled={disabled}
                            showError={showError}
                            type="number"
                            sx={{'& .MuiOutlinedInput-root' : {height: '100%'} ,height: '100%'}}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid spacing={0} container sx={{padding: '20px 30px'}}>
                <Grid item xs={12}>
                    <span><strong>INVESTIGATION TEAM</strong>(List all personnel to be involved in the investigation based on the gihgest actual or potential serverity rating listed above)</span>
                </Grid>
                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', border: '1px solid black', background: '#D3D3D3'}}>
                    <Grid item xs={2} sx={{borderRight: '1px solid black', display: 'flex', flexDirection: 'column', position: 'relative', padding: '5px'}}>
                        <span><strong>1. Project ESH Specialist</strong></span>
                        <span><strong>2. Immediate Supervisor</strong></span>
                        <br/>
                        <span style={{textAlign: 'center', position: 'absolute', bottom: '0', left: '0', right: '0'}}>(Rating 1-25 Severity)</span>
                    </Grid>
                    <Grid item xs={2} sx={{borderRight: '1px solid black', display: 'flex', flexDirection: 'column', position: 'relative', padding: '5px'}}>
                        <span><strong>1. Project ESH Supervisor / Manager</strong></span>
                        <span><strong>2. Construction Manager</strong></span>
                        <br/>
                        <span style={{textAlign: 'center', position: 'absolute', bottom: '0', left: '0', right: '0'}}>(Rating 1-25 Severity)</span>
                    </Grid>
                    <Grid item xs={2} sx={{borderRight: '1px solid black', display: 'flex', flexDirection: 'column', position: 'relative', padding: '5px'}}>
                        <span><strong>1. Site ESH Sr. Supervisor / Assistant ESH Manager</strong></span>
                        <span><strong>2. Construction Manager</strong></span>
                        <br/>
                        <span style={{textAlign: 'center', position: 'absolute', bottom: '0', left: '0', right: '0'}}>(Rating 7 or greater Severity)</span>
                    </Grid>
                    <Grid item xs={3} sx={{borderRight: '1px solid black', display: 'flex', flexDirection: 'column', position: 'relative', padding: '5px'}}>
                        <span><strong>1. Project Manager / Department Head</strong></span>
                        <span><strong>2. Corporate ESH Manager</strong></span>
                        <br/>
                        <span style={{textAlign: 'center', position: 'absolute', bottom: '0', left: '0', right: '0'}}>(Rating 15 or greater Severity)</span>
                    </Grid>
                    <Grid item xs={3} sx={{display: 'flex', flexDirection: 'column', position: 'relative', padding: '5px'}}>
                        <span><strong>1. Corporate ESH Head</strong></span>
                        <span><strong>2. Investigation Committee</strong></span>
                        <span><strong>3. Qualified Independento of the Operations Unit where the incident occured appointed by FB Operations Head / President</strong></span>
                        <br/>
                        <span style={{textAlign: 'center', position: 'absolute', bottom: '0', left: '0', right: '0'}}>(Rating 20 or greater Severity)</span>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', border: '1px solid black'}}>
                    <Grid item xs={2} sx={{borderRight: '1px solid black'}}>
                        <WrappedTextField
                            id="investigation_team_1"
                            label=""
                            fullWidth
                            multiline
                            value={reportData.investigation_team_1}
                            size="small"
                            onBlur={(value)=>onChange(value,'investigation_team_1')}
                            disabled={disabled}
                            showError={showError}
                            sx={{'& .MuiOutlinedInput-root' : {height: '100%'} ,height: '100%'}}
                        />
                    </Grid>
                    <Grid item xs={2} sx={{borderRight: '1px solid black'}}>
                        <WrappedTextField
                            id="investigation_team_2"
                            label=""
                            fullWidth
                            multiline
                            value={reportData.investigation_team_2}
                            size="small"
                            onBlur={(value)=>onChange(value,'investigation_team_2')}
                            disabled={disabled}
                            showError={showError}
                            sx={{'& .MuiOutlinedInput-root' : {height: '100%'} ,height: '100%'}}
                        />
                    </Grid>
                    <Grid item xs={2} sx={{borderRight: '1px solid black'}}>
                        <WrappedTextField
                            id="investigation_team_3"
                            label=""
                            fullWidth
                            multiline
                            value={reportData.investigation_team_3}
                            size="small"
                            onBlur={(value)=>onChange(value,'investigation_team_3')}
                            disabled={disabled}
                            showError={showError}
                            sx={{'& .MuiOutlinedInput-root' : {height: '100%'} ,height: '100%'}}
                        />
                    </Grid>
                    <Grid item xs={3} sx={{borderRight: '1px solid black'}}>
                        <WrappedTextField
                            id="investigation_team_4"
                            label=""
                            fullWidth
                            multiline
                            value={reportData.investigation_team_4}
                            size="small"
                            onBlur={(value)=>onChange(value,'investigation_team_4')}
                            disabled={disabled}
                            showError={showError}MuiTextField-root
                            sx={{'& .MuiOutlinedInput-root' : {height: '100%'} ,height: '100%'}}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <WrappedTextField
                            id="investigation_team_5"
                            label=""
                            fullWidth
                            multiline
                            value={reportData.investigation_team_5}
                            size="small"
                            onBlur={(value)=>onChange(value,'investigation_team_5')}
                            disabled={disabled}
                            showError={showError}
                            sx={{'& .MuiOutlinedInput-root' : {height: '100%'} ,height: '100%'}}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{marginTop: '20px', display: 'flex', justifyContent: 'center'}}>
                      <img src={SeverityMatrix} alt="Incident Severity Matrix" style={{maxWidth: '80vw', margin: '0 auto'}} />
            </Grid>
          </Grid>
    </>
}

export default IncidentSeverity