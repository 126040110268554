import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Grid } from '@mui/material';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';

import Select from "components/common/select";
import DataTable from 'components/common/table';
import { getProjectsList, getReportsList } from "utils/api/reports"; 
import { reportTableColumns, noiClass, /*, nmrClass */ 
finishedReportTableColumns} from 'utils/constants';
import { GlobalContext } from 'context/global';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, padding: 0}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const reportStatusRoleMap = {
  employee: [5],
  supervisor: [5,[2,4],1,3,0],
  esh: [5,[2,4],1],
  pm: [5,[2,4],3]
}

const reportStatus = {
  employee: ['Completed Reports'],
  supervisor: [
    'Completed Reports',
    'Returned by ESH / PM',
    'For ESH Approval',
    'For PM Approval',
    'Draft'
  ],
  esh: [
    'Completed Reports',
    'Returned by ESH / PM',
    'For ESH Approval',
  ],
  pm: [
    'Completed Reports',
    'Returned by ESH / PM',
    'For PM Approval',
  ]
}

const reportTypes = [
  'All',
  'Notice of Incident',
  'Near Miss'
]

const  Reports = ({role}) => {
  const { reportParam } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [reportType, setReportType] = React.useState(0);
  const [reportsList, setReportsList] = React.useState([]);
  const { state } = React.useContext(GlobalContext);
  const { projects } = state;

  const handleChange = (event, newValue) => {
    const { hash } = location;
    if( hash !== '') {
      navigate('/reports')
    }
    setValue(newValue);
  };

  const handleSelectChange = (event) => {
    setReportType(event.target.value);
  }

  const updateReportsList = (data=[]) => {
    return data.map(item => {
      const { id, project_facility, incident_class, ...rest} = item;
      const incident_classes_names = Object.values(noiClass);
      const [reportType] = rest.report_id.split('-');
      return {
        ...rest,
        system_id: id,
        project_facility: projects[project_facility] ? projects[project_facility].name : project_facility,
        incident_class: reportType === "NMR" ? "Near Miss" : (incident_classes_names[incident_class-1] ? incident_classes_names[incident_class-1] : incident_class)
      }
    })
  }

  const getReportListData = () => {
    getReportsList(reportStatusRoleMap[role][value], reportType).then(res => {
        setReportsList(updateReportsList(res.data.data).reverse())
    })
  }

  React.useEffect(()=>{
    getProjectsList().then(res => console.log(res));
  },[])

  const { hash } = location;
  React.useEffect(()=>{
    const tab = hash.replace('#','').replaceAll('%20', ' ').toLowerCase();
    const tabs = reportStatus[role];
    tabs.forEach((item, index)=> {
      if(tab === item.toLowerCase()) {
        setValue(index);
        return
      }
    })
  },[hash]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(()=>{
    getReportListData();
  }, [value, projects, reportType]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(()=>{
  },[reportsList])

  const openForm = ({row}) => {
    const { system_id } = row;
    navigate(`/reports/${system_id}`)
  }

  if(reportParam) return <Outlet /> 

  const reportTypeOptions = reportTypes.map((value,index)=> ({value: index, label: value}))

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{padding: '20px 10px'}}>
        <Grid container spacing={3}>
            <Grid item xs={role === 'supervisor' ? 8 : 10}>
                <Typography variant="h6">{reportTypes[reportType]}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Select 
                    id='report-type'
                    value={reportType}
                    label='Report Type'
                    onChange={handleSelectChange}
                    options={reportTypeOptions}
              />
            </Grid>
            {role === 'supervisor' &&
            <Grid item xs={2}>
                <Button onClick={()=>navigate("create")} fullWidth color="primary" variant="contained">
                    Create
                </Button>
            </Grid>
            }
        </Grid>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {reportStatus[role].map((text,index)=><Tab key={text} label={text} {...a11yProps(index)} />)}
        </Tabs>
      </Box>
      <TabPanel value={value} index={value}>
        <DataTable data={reportsList} columns={value === 0 ? finishedReportTableColumns :reportTableColumns} onRowDoubleClick={openForm} idName='report_id' />
      </TabPanel>
    </Box>
  );
}

export default Reports