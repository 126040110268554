import axios from 'axios'

const translateToFormData = (payload) => {
    const formData = new FormData();
    Object.keys(payload).forEach(key => {
        const currentItem = payload[key]
        const isArray = Array.isArray(currentItem) 
        const includeInForm = isArray ? currentItem.length > 0 : (currentItem === 0 ? "0" : Boolean(currentItem))
        if(includeInForm) {
            if(key==='image') {
                currentItem.forEach((imageItem) => {
                    if( typeof imageItem.image === 'string') {
                        formData.append(`image_label[${imageItem.id}]`, imageItem.label);
                    }else {
                        formData.append(key+"[][image]", imageItem.image);
                        formData.append(key+"[][label]", imageItem.label);
                    }
                })
            }else {
                formData.append(key, isArray ? JSON.stringify(currentItem) : currentItem)
            }
        }
    })
    return formData
}

const getReportsList = (reportStatus, reportType) => axios.get(`report/?status=${reportStatus}${reportType ? `&report_type=${reportType}` : ''}`)

const getProjectDetails = () => axios.get("report/store")

const createReport = (payload) => axios.post("report/store",translateToFormData(payload), { headers: {'Content-Type': 'multipart/form-data'}})

const updateDraft = (id, payload) => axios.post(`report/put/${id}`, translateToFormData(payload), { headers: {'Content-Type': 'multipart/form-data'}});

const submitDraft = (id, payload) => axios.post(`report/put/${id}`, translateToFormData({...payload, status: 1}), { headers: {'Content-Type': 'multipart/form-data'}})

const getReportData = (reportId) => axios.get(`report/show?id=${reportId}`)

const rejectWithComment = (payload) => axios.post("report/store/comment", translateToFormData(payload))

const approveReport = (reportId) => axios.put(`report/approve/${reportId}`)

const deleteImageFromDb = (id) => axios.post(`report/image/delete/${id}`);

const getProjectsList = () => axios.get("report/projects");

export { getReportsList, getProjectsList, getProjectDetails, createReport, updateDraft, getReportData, rejectWithComment, approveReport, submitDraft, deleteImageFromDb }

