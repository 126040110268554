import React from 'react';
import axios from 'axios';

import { BrowserRouter } from "react-router-dom";

import AppRoute from "./routes";
import { getToken } from "utils/api/auth";
import { API_URL } from 'utils/constants';

import './App.scss';

axios.defaults.baseURL = API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
const token = getToken();
if(token) axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

function App() {
  return (
    <BrowserRouter>
      <AppRoute />
    </BrowserRouter>
  );
}

export default App;
