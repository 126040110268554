import { Grid, Typography } from "@mui/material";
import CheckboxesGroup from "components/common/checkboxgroup";
import MultiFields from "components/common/multifields";
import WrappedTextField from "components/common/textfield";
import React from "react";
import { nearMissRecommendationOptions } from "utils/constants";

const NearMissForm = ({reportData, onChange, disabled, showError}) => {
    return <>
        <Grid container spacing={3} sx={{padding: '20px 30px'}}>
            <Grid item xs={12}>
                <Typography variant="h5" >Near Miss Information</Typography>
            </Grid>
            <Grid item xs={12}>
                <WrappedTextField
                      id="near-miss-incident-desc"
                      label="Near Miss Incident Description: (Personnel Injury)"
                      fullWidth
                      multiline
                      value={reportData.near_miss_description}
                      size="small"
                      onBlur={(value)=>onChange(value,'near_miss_description')}
                      disabled={disabled}
                      showError={showError}
                  />
            </Grid>
            <Grid item xs={12}>
                <WrappedTextField
                      id="near-miss-action-desc"
                      label="What action, if any, did you take during and after the Near Miss:"
                      fullWidth
                      multiline
                      value={reportData.near_miss_action}
                      size="small"
                      onBlur={(value)=>onChange(value,'near_miss_action')}
                      disabled={disabled}
                      showError={showError}
                  />
            </Grid>
            <Grid item xs={12}>
                <WrappedTextField
                      id="near-miss-personal-method"
                      label="Offer YOUR method of addressing the incident"
                      fullWidth
                      multiline
                      value={reportData.method_of_addressing}
                      size="small"
                      onBlur={(value)=>onChange(value,'method_of_addressing')}
                      disabled={disabled}
                      showError={showError}
                  />
            </Grid>
            <Grid item xs={6}>
                <CheckboxesGroup 
                    label="What type of loss could this near miss have caused?"
                    options={["Personal Injury", "Asset / Property Damage", "Environmental", "Operational Interruption"]}
                    value={reportData.type_of_loss.split(",")}
                    onChange={(value)=>onChange(value.join(",").replace(/(^,|,$)/g, ""), "type_of_loss")}
                    disabled={disabled}
                    showError={showError}
                 />
            </Grid>
            <Grid item xs={12}>
                <WrappedTextField
                      id="near-miss-personal-method"
                      label="Indicate and explain “Potential Severity” Rating"
                      fullWidth
                      multiline
                      value={reportData.potential_severity_explanation}
                      size="small"
                      onBlur={(value)=>onChange(value,'potential_severity_explanation')}
                      disabled={disabled}
                      showError={showError}
                  />
            </Grid>
            <Grid item xs={12}>
                <MultiFields
                    label="List any recommendation to prevent/minimize future occurrence"
                    value={reportData.recommendations} 
                    onChange={(value)=>onChange(value,'recommendations')} 
                    options={nearMissRecommendationOptions}
                    disabled={disabled}
                    showError={showError}
                    />
            </Grid>
        </Grid>
    </>
}

export default NearMissForm