import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { GlobalProvider } from "context/global"; 
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#fff',
    },
    tertiary: {
      light: '#dd33fa',
      main: '#d500f9',
      dark: '#9500ae',
      contrastText: '#fff',
    }
  },
  overrides: {
    MuiCssBaseline: {
        '@global': {
            //override the pseudo-classes
            '.Mui-disabled': { opacity: 1, color: 'black', borderColor: 'black', '-webkit-text-fill-color': 'black' }
        }
    }
},
});

ReactDOM.render(
  <React.StrictMode>
    <GlobalProvider >
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </GlobalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

