import { Button, Grid } from '@mui/material';
import React from 'react';
import ImageUploading from 'react-images-uploading';
import Swal from 'sweetalert2';
import { deleteImageFromDb } from 'utils/api/reports';
import WrappedTextField from './textfield';

const ImageUploader = ({onChange, disabled, value, initialValue}) => {
  const [images, setImages] = React.useState(value);
  const [imagevalues, setImageValues] = React.useState(value);
  const maxNumber = 20;

  const handleChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    setImageValues(imageList.map(image => {
        if( image.hasOwnProperty('id') ) return image;
        return ({image: image.file, label:''})
    }))
  };

  const handleLabelChange = (value, index) => {
    const tempVals = imagevalues;
    tempVals[index].label = value
    setImageValues(tempVals);
  }

  React.useEffect(()=>{
    onChange(images, imagevalues);
  },[images, imagevalues]) // eslint-disable-line 

  React.useEffect(()=>{
    setImages(initialValue)
    setImageValues(initialValue)
  },[initialValue])

  const handleImageRemove = (deletedIndex, callback) => {
    Swal.fire({
      title: "Are you sure you want to remove the image?",
      icon: "question",
      showCancelButton: true,
      showConfirmButton: true
    }).then((res)=>{
      if(res.isConfirmed){
        const deletedImage = imagevalues[deletedIndex];
        if(deletedImage.hasOwnProperty('src')) {
          deleteImageFromDb(deletedImage.id).then(res => {
            handleChange(imagevalues.filter((val, index) => index !== deletedIndex))
          })
        } else {
          callback(deletedIndex);
        }
        Swal.fire({
          title: 'Image successfully deleted',
          icon: 'success',
          showConfirmButton: true
        })
      }
    })

  }

  return (
      <ImageUploading
        multiple
        value={images}
        onChange={handleChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemove,
        }) => (
          <div className="upload__image-wrapper">
            <Grid container spacing={3}>
            {!disabled &&
                <Grid item xs={12} sx={{marginBottom: '20px'}}>
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={onImageUpload}
                    >
                    Upload Image
                    </Button>
                </Grid>
            }
            {imageList.map((image, index) => (
                <Grid item xs={3} key={image['data_url'] || image.src} sx={{border: '1px solid black', borderRadius: "5px", padding: '20px', position: 'relative'}}>
                    <div className="image-item">
                        <img src={image['data_url'] || image.src} alt="" width="100%" />
                        {!disabled && 
                        <div className="image-item__btn-wrapper">
                          <Button 
                            variant="contained" 
                            color="error" 
                            size="small" 
                            onClick={() => handleImageRemove(index, onImageRemove)} 
                            sx={{position: 'absolute', right: '0', top: '0'}}>
                              x
                            </Button>
                        </div>}
                    </div>
                    <WrappedTextField 
                        fullWidth
                        id='image-label'
                        value={imagevalues[index] ? imagevalues[index].label : ''}
                        label='Image Label'
                        size='small'
                        multiline
                        onBlur={(value)=>handleLabelChange(value, index)}
                        disabled={disabled} />
                </Grid>
            ))}
            </Grid>

          </div>
        )}
      </ImageUploading>
  );
}


export default ImageUploader;