import React from "react";
import { initialGlobalState, globalReducer} from "reducer/global.reducer"; 

export const GlobalContext = React.createContext()

export const GlobalProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(globalReducer, initialGlobalState);

    return <GlobalContext.Provider value={{state, dispatch}}>
        {children}
    </GlobalContext.Provider>
}

