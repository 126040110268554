import axios from 'axios'
import cookie from 'react-cookies'

export const saveToken = (token) => {
    cookie.save('token',token, { path: '/' })
}

export const removeToken = () => {
    cookie.remove('token', { path: '/' })
}

export const getToken = () => {
    return cookie.load('token')
}

const login = (email, password) => axios.post('user/login', {email: email, password: password})

const logout = () => axios.get('user/logout');

const reauth = () => axios.get('user/check');

const authenticateGoogle = (payload) => axios.post('google/login', payload);

export const AUTH = {
    login,
    reauth,
    logout,
    authenticateGoogle
}

export { login, reauth, logout, authenticateGoogle }