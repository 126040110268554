import React from "react";
import {
    Routes,
    Route,
    Navigate,
    useLocation
} from "react-router-dom";
import Login from "components/pages/Login";
import Reports from "components/pages/Reports";
import Layout from "components/pages/Layout";
import Home from "components/pages/Home";
import ReportForm from "components/pages/ReportForm";

import Loader from "components/common/loader";

import { GlobalContext } from "context/global";
import { getToken, reauth } from "utils/api/auth";

import GLOBAL_ACTIONS from "reducer/global.actions";
import { getProjectDetails } from "utils/api/reports";


const SecureRoute = ({element}) => {
    let location = useLocation();
    const { state } = React.useContext(GlobalContext);
    const { authenticated } = state.user;

    return authenticated ? element : <Navigate to="/login" state={{ from: location }} replace />
}

const AuthenticationCheck = ({children}) => {
    const { dispatch } = React.useContext(GlobalContext);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(()=>{
      const token = getToken();
      if(token) {
        reauth().then(res => {
          if(res.status === 200) {
            const { user, role } = res.data;
            dispatch({type: GLOBAL_ACTIONS.auth_success, payload: {role: role, user: user}})
            getProjectInfo();
            setLoading(false);
          } else {
            setLoading(false);
          }
        }).catch(e => {
            console.error(e);
            setLoading(false);
        })
      } else {
        setLoading(false);
      }
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    const getProjectInfo = () => {
      getProjectDetails().then(res => {
          if(res.status === 200) {
              const { projects, supervisor, pm } = res.data.data;
              dispatch({type: GLOBAL_ACTIONS.set_user_info, payload: {projects, pm, supervisor }})
          }
      })
    }

    if(loading) {
        return <Loader />
    } else {
        return children
    }
}

const AppRoute = () => {
    const { state } = React.useContext(GlobalContext);
    const { user } = state;

    return (
        <AuthenticationCheck>
            <Routes>
                <Route path="/login" element={<Login />}/>
                <Route path="/" element={<SecureRoute element={<Layout />} />} >
                  <Route path="home" element={<SecureRoute element={<Home role={user.role.name} name={user.info.name} />} />} />
                  <Route path="reports" element={<SecureRoute element={<Reports role={user.role.name} />} />} >
                    <Route path=":reportParam" element={<SecureRoute element={<ReportForm role={user.role.name} />} />} >
                      <Route path=":reportId" element={<SecureRoute element={<ReportForm role={user.role.name} />} />} />
                    </Route> 
                  </Route>
                  <Route path="*" element={<Navigate to="/" />} />
                </Route>

            </Routes>
        </AuthenticationCheck>
    )
}

export default React.memo(AppRoute);